@import "../../scss/fonts.scss";

// стили должны совпадать с .html-raw-content в закрытой части
.html-block {
  font-size: 16px;
  line-height: 24px;
  font-family: $PT_SANS;

  strong {
    font-weight: bold;
  }

  i {
    font-style: revert;
  }

  ol,
  ul {
    list-style: revert;
    padding-left: revert;
  }

  h2,
  h3,
  h4 {
    font-style: revert;
    font-size: revert;
    font-weight: revert;
  }

  .media {
    position: relative;
  }

  .image-style-side {
    float: right;
    max-width: 50%;
  }

  .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
  }

  .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }

  .table {
    margin: 0.9em auto;
    margin-bottom: 0.9em;
    display: table;
  }

  .table table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double #b3b3b3;
  }

  .table th {
    text-align: left;
  }

  .table table th {
    min-width: 2em;
    padding: 0.4em;
    border: 1px solid #bfbfbf;
  }

  .table table th {
    font-weight: 700;
    background: rgba(0, 0, 0, 0.05);
  }

  .table table td,
  .ck-content .table table th {
    min-width: 2em;
    padding: 0.4em;
    border: 1px solid #bfbfbf;
  }

  img {
    width: 100%;
  }

  figcaption {
    text-align: center;
  }

  p {
    margin: revert;
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
