@import "../../vars.scss";

.Button {
  width: 192px;
  height: 48px;
  border: none;
  border-radius: 4px;
  outline: none;
  background: $WHITE;
  color: $WHITE;
  font-size: $DEFAULT_TEXT_SIZE;
  padding: 0;
  transition: 0.3s;

  &.primary {
    background-color: $BTN_PRIMARY;
    border: 1px solid $BTN_PRIMARY_BORDER;

    &:hover {
      background-color: $BTN_PRIMARY_HOVER;
    }

    &:active {
      background-color: $BTN_PRIMARY_CLICK;
      border: 1px solid $BTN_PRIMARY_BORDER_CLICK;
    }
  }
  &.danger {
    background-color: $BTN_DANGER;

    &:hover {
      background-color: $BTN_DANGER_HOVER;
    }

    &:active {
      background-color: $BTN_DANGER_CLICK;
    }
  }
  &.sub {
    background-color: $BTN_SUB;
    color: $BTN_SUB_TEXT;

    &:hover {
      background-color: $BTN_SUB_HOVER;
      color: $BTN_SUB_HOVER_TEXT;
    }

    &:active {
      background-color: $BTN_SUB_CLICK;
      color: $BTN_SUB_HOVER_TEXT;
    }
  }
  &.link {
    background: none;
    color: $PRIMARY;
    width: auto;
    height: auto;
  }
  &.disabled {
    background: $DISABLED_BG;
    color: $DISABLED_COLOR;
    cursor: initial;
  }
  &.decline {
    background: $WHITE;
    color: $HEADER_COLOR;
  }
}
