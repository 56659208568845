@import "../../vars.scss";

.Avatar-wrapper {
  position: relative;
}

.Avatar {
  background: $IMG_BG;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $DEFAULT_TEXT_SIZE;
  font-family: $AG_HELVETICA_BOLD;
  text-transform: uppercase;
  color: $MORE_BUTTON_BG;
  justify-self: center;
  letter-spacing: -1px;
  position: relative;

  &_notifications {
    z-index: 2;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 18px;
    height: 18px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='9' fill='%23FF9900'/%3E%3Cpath d='M12.9716 11.599L12.2848 10.5247C11.9676 10.0288 11.8 9.4608 11.8 8.88271V7.9416C11.8 6.75349 10.9552 5.74857 9.7999 5.42461V4.75079C9.7999 4.33672 9.44109 4 8.99987 4C8.55864 4 8.19983 4.33672 8.19983 4.75079V5.42461C7.0446 5.74857 6.19976 6.75349 6.19976 7.9416V8.88271C6.19976 9.4608 6.03215 10.0284 5.71534 10.5243L5.02852 11.5987C4.99132 11.6569 4.99051 11.7289 5.02612 11.7879C5.06172 11.8468 5.12773 11.8832 5.19973 11.8832H12.8C12.872 11.8832 12.9384 11.8468 12.974 11.7882C13.0096 11.7297 13.0084 11.6568 12.9716 11.599Z' fill='white'/%3E%3Cpath d='M7.73684 12.2774C7.96308 12.703 8.44203 13 8.99999 13C9.55795 13 10.0369 12.703 10.2632 12.2774H7.73684Z' fill='white'/%3E%3C/svg%3E%0A");
  }
}

@media screen and (min-width: 640px) {
  .Avatar {
    width: 40px;
    height: 40px;
    font-size: $BIGGER_TEXT_SIZE;
  }
}

@media screen and (min-width: 1025px) {
  .Avatar {
    width: 48px;
    height: 48px;
    font-size: $BIGGER_TEXT_SIZE;
  }
}
