@import "../../vars.scss";

.BigLoader {
  z-index: 20;
  position: fixed;
  left: 0;
  right: 0;
  top: 102px;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .throbber-container {
    background: none;
    margin-bottom: 20vh;

    .line-1, .line-2 {
      color: $WHITE;
    }
  }
}

@media screen and (max-width: 639px) {
  .BigLoader {
    top: 80px;
    right: 48px;
  }
}