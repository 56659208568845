@import "../../../Common/vars.scss";

.file-wrapper {
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 184px;
  min-height: 160px;
  min-width: 160px;

  img {
    width: 100%;
    height: 100%;
    max-height: 184px;
    object-fit: cover;
  }

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: $BLACK_COLOR;
    opacity: 0.3;
    z-index: 1;
  }
  &:before {
    position: absolute;
    content: "";
    background-image: url("../../../Common/images/photo.png");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  .file-application {
    width: 192px;
    height: 184px;
    background-image: url("data:image/svg+xml, %3Csvg width='32' height='28' viewBox='0 0 32 28' fill='none' version='1.1' id='svg6' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M 11.99998,4.00002 H 16 v 1.99996 h 4 V 0 H 0 V 5.99998 H 4.00002 V 4.00002 H 8.00004 V 24 H 4.00002 v 4 h 11.998 v -4 h -3.99804 z' fill='%23689857' id='path2' /%3E%3Cpath d='m 16,10.00004 v 5.99998 h 4 v -1.99996 h 2 V 24 h -2 v 4 h 8 V 24 H 26 V 13.99998 h 2 v 1.99998 h 4 V 9.99998 Z' fill='%23689857' id='path4' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: relative;
  }

  &.application {
    &::before {
      display: none;
      width: 0;
      height: 0;
    }
  }
}
