@import '../../vars.scss';

.textarea-wrapper {
  display: flex;
  width: 100%;
  margin: 15px 0;

  textarea {
    display: flex;
    width: 100%;
    padding: 16px;
    flex: 0 1 100%;
    resize: vertical;
    border-radius: 4px;
    outline: none;
    border: none;
    font-family: $AG_HELVETICA;
  }
}
