@import "../../scss/colors.scss";

.ButtonComponent {
  max-width: 192px;
  width: 100%;
  border: none;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
  transition: 0.3s;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);

  &--small {
    padding: 15px 12px;
  }

  &--large {
    padding: 19px 12px;
  }

  &--default {
    background: $color-white-1;
    color: $color-black-1;
  }

  &--primary {
    background-color: $color-green-1;
    border: 1px solid $color-green-3;
    color: $color-white-1;

    &:hover {
      background-color: $color-green-4;
    }
  }

  &--danger {
    background-color: $color-red-1;

    &:hover {
      background-color: $color-red-2;
    }

    &:active {
      background-color: $color-red-3;
    }
  }

  &--sub {
    background-color: $color-white-1;
    color: $color-green-1;

    &:hover {
      background-color: $color-green-4;
      color: $color-white-1;
    }

    &:active {
      background-color: $color-green-5;
      color: $color-white-1;
    }
  }

  &--disabled {
    background: $color-grey-4;
    color: $color-grey-5;
    cursor: initial;
  }

  &--decline {
    background: $color-white-1;
    color: $color-black-2;
  }

  &--buy {
    background-color: $color-white-1;
    color: $color-green-1;
    border: 1px solid $color-green-3;
  }
}
