@import "../../../Common/vars.scss";

.error {
  display: flex;
  gap: 124px;

  &__status {
    padding-top: 90px;
  }

  &__header {
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 41px;
    color: #454545;
  }

  &__description {
    margin-top: 16px;
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    color: #454545;
  }

  &__link {
    margin-top: 60px;
    width: fit-content;
  }

  &__back {
    position: relative;
    margin-top: 60px;

    &::before {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAMCAYAAAB4MH11AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACaSURBVHgBtZOxDcJADEW/g+gZISMkbJCSCprQshIjMEJqQMoIMMJtwAAo9/E16BRdup9X2tJ/si0bBPTP/RXg8bthN3TvkPcqCKDFD4h6O9l4Gps67xlEnO/tjYaLJ4Z8EplgSSIVlCTWP9qX1xusgUsqI3ZYiyi+QeK/IvqKJl8RhMzDh4PwyKXwVJc8WvrkUrhMQMZQCk/8AJqeYbGtKPmrAAAAAElFTkSuQmCC)
        no-repeat 50% 50%;
      content: "";
      transform: rotate(180deg);
      height: 12px;
      position: absolute;
      left: 30px;
      top: 16px;
      width: 24px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .error {
    gap: 32px;
  }
}
@media screen and (max-width: 639px) {
  .error {
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 24px;

    &__status {
      padding-top: 0;
    }

    &__back {
      margin-top: 24px;
    }

    &__header {
      font-size: 24px;
      line-height: 31px;
    }

    &__description {
      margin-top: 8px;
      font-size: 16px;
      line-height: 24px;
    }

    .error-image {
      margin-left: 16px;
    }
  }
}
