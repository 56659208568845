@import "../Common/vars.scss";

html,
body,
#root {
  position: relative;
}

html {
  // scroll-behavior: smooth;
}

.veil {
  position: fixed;
  width: 100%;
  background: #000;
  z-index: 1002;
  opacity: 0.2;
  display: none;
}

.main {
  display: flex;
  flex-direction: column;
}

a {
  color: $LINK_COLOR;
  text-decoration: none;
}

a,
p,
input,
span {
  font-family: $AG_HELVETICA;
}

.header {
  h1,
  h2,
  h3 {
    color: $HEADER_COLOR;
    text-transform: uppercase;
    font-family: $AG_HELVETICA;
    font-weight: bold;
    margin-bottom: 32px;
  }

  h1 {
    font-size: 32px;
    margin-top: 88px;
    font-family: $AG_HELVETICA_BOLD;
  }

  h2 {
    font-size: 24px;
    font-family: $AG_HELVETICA_BOLD;
  }
}

.block {
  padding: 88px 0;
  border-bottom: 1px solid $BB_COLOR;
}

.showing {
  color: $MENU_TXT_COLOR;
  display: block;
  margin-bottom: 24px;
  font-size: $DEFAULT_TEXT_SIZE;
  font-family: $AG_HELVETICA;
}

p {
  line-height: 24px;
  margin-bottom: 24px;
}

.button {
  width: 191px;
  height: 47px;
  text-align: center;
  line-height: 48px;
  background: $LINK_COLOR;
  color: $WHITE;
  margin-top: 48px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  border: 1px solid $GREEN_LINE;
  box-shadow: $SHADOW;
  outline: none;

  &:hover {
    background: $BUTTON_HOVER;
    border: 1px solid $GREEN_LINE;
    color: $WHITE;
  }

  &:active {
    background: $BUTTON_ACTIVE;
    border: 1px solid $BUTTON_HOVER;
    color: $WHITE;
  }

  &.true {
    background: $DISABLED_BG;
    color: $DISABLED_COLOR;
  }
}

.to,
.back {
  margin-top: 48px !important;
  color: $LINK_COLOR;
  background: $WHITE;
  box-shadow: $SHADOW;
  border: 1px solid $WHITE;
  width: 240px;
  position: relative;
  text-align: start;
  span {
    margin-left: 49px;
  }

  &:after {
    content: "";
    position: absolute;
    width: 24px;
    height: 12px;
    background: url("../Common/images/arrow.png") no-repeat 50% 50%;
    top: 18px;
    right: 24px;
  }
}

.back:after {
  left: 16px;
  transform: rotate(180deg);
}

.component {
  display: flex;
  max-width: 1128px;
  align-self: center;
  flex: 0 0 100%;
  width: 100%;
  flex-direction: column;
  position: relative;

  .header {
    h3 {
      margin-top: 64px;
    }
  }

  &-date {
    margin-top: 32px;
  }
}

.item {
  &__status {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 9px 27px 9px 36px;
    background: rgba(33, 33, 33, 0.64);
    border-radius: 4px;
    font-size: $DEFAULT_TEXT_SIZE;

    &.rejected,
    &.inwork,
    &.moderated,
    &.created,
    &.finished {
      &:after {
        position: absolute;
        content: "";
        height: 12px;
        width: 12px;
        left: 16px;
        top: 12px;
        background-repeat: no-repeat;
      }
    }

    &.rejected {
      &:after {
        top: 11px;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.09938 6.00009L11.7719 1.32729C12.076 1.02334 12.076 0.531906 11.7719 0.22796C11.468 -0.0759865 10.9766 -0.0759865 10.6726 0.22796L5.99993 4.90076L1.32737 0.22796C1.02329 -0.0759865 0.532001 -0.0759865 0.228062 0.22796C-0.0760205 0.531906 -0.0760205 1.02334 0.228062 1.32729L4.90062 6.00009L0.228062 10.6729C-0.0760205 10.9768 -0.0760205 11.4683 0.228062 11.7722C0.379533 11.9238 0.578696 12 0.777716 12C0.976736 12 1.17576 11.9238 1.32737 11.7722L5.99993 7.09942L10.6726 11.7722C10.8242 11.9238 11.0233 12 11.2223 12C11.4213 12 11.6203 11.9238 11.7719 11.7722C12.076 11.4683 12.076 10.9768 11.7719 10.6729L7.09938 6.00009Z' fill='%23F13C3C'/%3E%3C/svg%3E%0A");
      }
    }
    &.moderated,
    &.inwork {
      &:after {
        top: 11px;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 0C2.69147 0 0 2.69147 0 6C0 9.30853 2.69147 12 6 12C9.30853 12 12 9.30853 12 6C12 2.69147 9.30853 0 6 0ZM8.85352 9.10345C8.75601 9.20096 8.62802 9.25003 8.50003 9.25003C8.37204 9.25003 8.24396 9.20096 8.14655 9.10345L5.64651 6.60352C5.55249 6.51004 5.50003 6.38297 5.50003 6.25003V3C5.50003 2.72351 5.72397 2.50003 6 2.50003C6.27603 2.50003 6.49997 2.72351 6.49997 3V6.04303L8.85352 8.39648C9.04898 8.59204 9.04898 8.90799 8.85352 9.10345Z' fill='%23DD8500'/%3E%3C/svg%3E%0A");
      }
    }

    &.created {
      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 4.88285L4.10933 9L12 1.11716L10.8672 0L4.10933 6.74996L1.11715 3.75782L0 4.88285Z' fill='%2355BB31'/%3E%3C/svg%3E%0A");
      }
    }

    &.finished {
      background: $STATUS_FINISHED;

      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 4.88285L4.10933 9L12 1.11716L10.8672 0L4.10933 6.74996L1.11715 3.75782L0 4.88285Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }

    &-text {
      color: $WHITE;
    }
  }
}

.button-icon {
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0);
}

.button_primary {
  background: $BTN_PRIMARY;
  border: 1px solid $BTN_PRIMARY_BORDER;
  color: $WHITE;

  path {
    fill: $WHITE;
  }

  &:active {
    background: $BTN_PRIMARY_CLICK;
    border-color: $BTN_PRIMARY_BORDER_CLICK;
  }
}

.button_secondary {
  background: $BTN_SECONDARY;
  border: 1px solid $BTN_SECONDARY_BORDER;
  color: $WHITE;

  path {
    fill: $WHITE;
  }

  &:active {
    background: $BTN_SECONDARY_ACTIVE;
    border-color: $BTN_SECONDARY_ACTIVE_BORDER;
  }
}

.throbber-container {
  padding-top: 40px;
  position: relative;
  background: #f5f5f5;
  .line-1 {
    font-weight: normal;
    font-size: 18px;
    color: #000000;
    margin-bottom: 14px;
    text-align: center;
    font-family: $AG_HELVETICA;
  }

  .line-2 {
    font-size: 15px;
    color: #535b63;
    text-align: center;
    font-family: $AG_HELVETICA;
    outline: none;
  }

  .loader {
    position: relative;
    margin-top: 144px;
  }
}

.lightGreen {
  color: $PRIMARY !important;
}

@media screen and (max-width: 639px) {
  .main {
    // margin-right: 32px;
    margin-right: 48px;
  }
}

@media screen and (min-width: 640px) {
  .button_primary {
    &:hover {
      background: $BTN_PRIMARY_HOVER;
    }
  }

  .button_secondary {
    &:hover {
      background: $BTN_SECONDARY_HOVER;
    }
  }
}
