@import "../../scss/colors.scss";

.switchers {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &__title {
    color: $color-black-1;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 24px;
  }

  &__list {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
  }

  &__arrow {
        margin-right: 8px;
    }

  &__link {
    background-color: $color-white-1;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-black-1;
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 0.02em;
    border: 1px solid $color-grey-3;
    border-radius: 4px;
    padding: 8px 24px 8px 16px;
    cursor: pointer;

    &-active {
      background-color: $color-blue-1;
      border-color: $color-blue-1;
    }
  }
}
