.dummy-photo {
  background-color: rgb(158, 157, 157);
  width: 100%;
  min-width: 130px;
  height: 100%;
  min-height: 130px;
  color: white;
  text-align: center;
  background-image: url("../../../Common/images/photo.png");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;

  &__title {
    position: absolute;
    top: calc(50% + 15px);
    margin: 0;
  }
}
