@import "../../vars.scss";

@media screen and (min-width: 640px) {
  .InputWithIcon {
    input {
      &:hover {
        border-color: $HOVER;
      }
    }
  }
}

.InputWithIcon {
  height: 48px;
  position: relative;
  font-size: $DEFAULT_TEXT_SIZE;
  display: flex;
  flex: 1;

  &__isEmpty {
    input {
      background-color: #f8f8f8 !important;
      border-color: white;
      box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.24);
    }
  }

  input {
    flex: 1;
    border: 2px solid $WHITE;
    border-radius: 4px;
    outline: none;
    height: 48px;
    padding: 0 12px;
    background: #FFFEFE;
    transition: 0.3s;
    background-color: #fff;
      border-color: white;

      border: 1px solid $WHITE;

      &.success {
        box-shadow: none;
      }

    &:focus {
      border-color: $ACTIVE;
    }

    &::placeholder {
      color: $INPUT_PLACEHOLDER;
    }

    &:disabled {
      background: $DISABLED_BG;
      color: $DISABLED_COLOR;
      cursor: default;
      box-shadow: none;

      &:hover {
        border-color: $WHITE;
      }

      &::placeholder {
        color: $DISABLED_COLOR;
      }
    }
  }

  &.disabled {
    path {
      fill: $DISABLED_COLOR;
    }
  }

  & > *:last-child {
    z-index: 1;
    position: absolute;
    right: 0;
    top: 4px;
    width: 48px;
    height: 44px;
  }
}

@media screen and (max-width: 639px) {
  .InputWithIcon {
    input {
      background-color: #fff;
      border-color: white;
      border: 1px solid $WHITE;
      box-shadow: none;
      height: 38px;
      font-size: $DEFAULT_MOBILE_TEXT_SIZE;
    }

    &__isEmpty {
      input {
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.24);
        height: 38px;
        font-size: $DEFAULT_MOBILE_TEXT_SIZE;
        border: 1px solid $WHITE;
      }
    }

    .button-icon {
      height: 34px;
    }
  }
}