@import "../../../Common/vars.scss";

.Settings {
  width: calc(100% - 96px);
  padding: 48px;
  margin-bottom: 80px;
  background: $WHITE;
  box-shadow: 0px 2px 4px $SHADOW_BG_GREY;
  border-radius: 8px;



  &__section {
    h1 {
      color: $HEADER_COLOR;
      font-family: $AG_HELVETICA_BOLD;
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 32px;
    }

    p {
      font-size: 14px;
      color: $MENU_TXT_COLOR;
      text-transform: uppercase;
      margin: 0;
    }

    &:not(:first-child) {
      margin-top: 32px;
    }
  }

  &__line {
    margin: 48px -48px 48px;
    background-color: #e5e5e5;
    height: 1px;
    width: calc(100% + 96px);
  }

  &__buttons {
    display: flex;
    gap: 8px;

    .Button {
      max-width: 192px;
      width: 100%;
    }
  }

  &__textarea {
    width: calc(100% - 32px);
    resize: none;
    min-height: 104px;
    margin-bottom: 48px;
    box-shadow: 0px 1px 4px 0px $CHAT_ITEM_INNER_SHADOW inset;
    border: none;
    border-radius: 4px;
    outline: none;
    padding: 14px 16px 12px;
    font-size: $DEFAULT_TEXT_SIZE;
    font-family: $PT_SANS;

    height: 22px;

    &::placeholder {
      color: $CHAT_DESC;
    }
  }

  .radiobuttons-wrapper {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;

    .container {
      display: flex;
      flex-direction: row;
      margin-right: 16px;
      padding-left: 26px;

      .checkmark {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        left: 0px;
        text-align: center;

        &:after {
          left: 0;
          right: 0;
          text-align: center;
        }
      }

      .text-label {
        color: $DEFAULT_CHECK_SUPPORT_TEXT_COLOR;
      }

      & .checkmark:after {
        width: 8px;
        height: 8px;
        transform: rotate(45deg);
        background: $ACTIVE_MENU_LINK;
        border-radius: 50%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-bottom: none;
        border-right: none;
      }

      & input:checked ~ .checkmark {
        border: 1px solid $CHOOZEN_BORDER;
        background: $WHITE;
      }

      .checkmark {
        position: absolute;
        border: 1px solid $DISABLED_COLOR;
        background: $BB_COLOR;
      }
    }
  }

  .checklist-wrapper {
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;

    .checkbox {
      height: 22px;
      margin: 0;
      margin-left: 30px;
      margin-right: 26px;
      line-height: 22px;
      margin-bottom: 20px;

      .checkmark {
        width: 20px;
        height: 20px;

        &.false::after,
        &.true::after {
          top: 3px;
          left: 8px;
          width: 4px;
          height: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 639px) {
  .Settings {
    padding: 32px 0 48px;
    width: 100%;
    position: relative;
    margin-bottom: 0;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 100%;
      left: -24px;
      background-color: $WHITE;
      border-radius: 8px 0 0 0;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0px;
      left: 100%;
      right: -24px;
      background-color: #fff;
      border-radius: 0 8px 0 0;
    }

    &__section {
      h1 {
        margin-bottom: 24px;
      }
    }

    &__line {
      margin: 40px 0px;
      background-color: #e5e5e5;
      height: 1px;
      width: 100%;
    }
  }
}

@media (max-width: 540px) {
  .Settings {
    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;

      .Button {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
