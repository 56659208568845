@import "../../vars.scss";

.chat {
  &__header {
    height: 88px;
    background: $CHAT_ITEM;
    background: url("../../images/chatPattern.png");
    background-color: $CHAT_ITEM_BG;
    padding: 20px 48px;
    display: flex;
    align-items: center;
    border-radius: 8px 0 0 0;
  }

  &__logo {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: $WHITE;
    svg {
      width: 26;
      height: 23px;
      margin-bottom: 2px;
    }
  }

  &__admin {
    color: $MENU_TXT_COLOR;
    text-transform: uppercase;
    font-family: $AG_HELVETICA_BOLD;
    margin-left: 12px;
  }

  &__close {
    margin-left: auto;
    width: 26px;
    height: 24px;
    svg:last-child {
      display: none;
    }
  }
}

@media screen and (max-width: 1024px) {
  .chat {
    &__header {
      height: 64px;
      padding: 16px 24px;
    }
  }
}

@media screen and (max-width: 639px) {
  .chat {
    &__close {
      svg:last-child {
        display: block;
      }

      svg:first-child {
        display: none;
      }
    }
  }
}