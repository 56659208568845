@import "../../../Common/vars.scss";

.FileViewer-popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $POPUP_SHADOW;
  z-index: 9999;
}

.FileViewer {
  max-width: 1128px;
  max-height: 720px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  * {
    outline: none;
  }

  &__slide div {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    & > * {
      height: 100%;
      object-fit: contain;
      border-radius: 8px;
    }
  }

  &__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
  }

  &__all {
    display: flex;
    height: 48px;
  }

  &__btn {
    width: 48px;
    height: 48px;
    background: $WHITE;
    border-radius: 8px;

    &:last-child {
      margin-left: 30px;
    }

    &:first-child {
      margin-right: 30px;
    }
  }

  &__dot {
    width: 48px;
    height: 48px;
    overflow: hidden;
    padding: 0;
    border: none;
    outline: none;
    border-radius: 8px;
    background: $BLACK_COLOR;
    transition: 0.3s;
    position: relative;

    &:not(:first-child) {
      margin-left: 4px;
    }

    & > img,
    & > video {
      object-fit: cover;
      height: 100%;
      width: 100%;
      opacity: 0.7;
    }
  }

  &__video-icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-bottom: 2px;
    }
  }

  .carousel__dot--selected {
    border: 2px solid $GREEN_LINE;

    & > * {
      opacity: 1;
    }
  }

  &__close {
    position: absolute;
    right: 108px;
    top: 88px;
    transition: 0.3s;
    width: 100px;
    height: 100px;
    border-radius: 50%;

    &:hover {
      background: rgba($color: $WHITE, $alpha: 0.1);
    }
  }
}

@media (max-width: 900px) {
  .FileViewer {
    height: calc(100% - 190px);
    justify-content: center;

    .carousel {
      min-height: 300px;
    }

    &__slide {
      min-height: 300px;

      div {
        min-height: 100%;
        overflow: visible;

        & > * {
          object-fit: contain;
          border-radius: 8px;
          min-height: 100%;
        }

        .FileViewer__video {
          background-color: $BLACK_COLOR;
          min-height: 100%;
          width: 95%;
        }

        .FileViewer__photo {
          margin: 0 5px;
          min-height: 100%;
          width: 95%;
        }
      }
    }

    &__slide-photo {
      min-height: 300px;
      overflow: visible;

      & > * {
        object-fit: contain;
        border-radius: 8px;
        min-height: 300px;
      }

      .FileViewer__photo {
        margin: 0 5px;
        width: 100%;
        min-height: 100%;
      }
    }
  }
}

@media screen and (max-width: 639px) {
  .FileViewer-popup {
    right: 48px;
  }

  .FileViewer {
    &__all {
      display: none;
    }

    &__btn {
      max-width: 108px;
      flex-grow: 1;

      &:last-child {
        margin-left: 4px;
      }

      &:first-child {
        margin-right: 4px;
      }
    }

    &__close {
      top: 15px;
      right: 50%;
      transform: translateX(50%);
    }
  }
}
