@import "../../../scss/colors.scss";

.my-points {
  width: 100%;

  &__content {
    padding: 49px 0;
  }

  &__header {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 39px;
    margin-bottom: 48px;
    padding-bottom: 0;

    &-active {
      border-bottom: 1px solid $color-grey-3;
      padding-bottom: 39px;
    }

    &-count {
      font-size: 32px;
      color: $color-grey-5;
      margin-bottom: 15px;
      font-weight: 900;

      &-active {
        color: $color-green-1;
      }
    }

    &-text {
      font-size: 16px;
      color: $color-grey-9;
      text-transform: uppercase;
      margin-bottom: 24px;
    }
    #btn {
      position: absolute;
      top: 0;
      right: 0px;
    }
  }
}

@media (max-width: 830px) {
  .my-points {
    .tabs {
      display: none;
    }
  }
}
