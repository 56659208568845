@import "../../scss/colors.scss";

.tabs {
  width: 100%;
  border: 1px solid $color-white-1;
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0px $color-grey-6 inset;
  height: 40px;
  display: flex;

  &__item {
    background-color: initial;
    border: none;
    flex: 1;
    height: 100%;
    box-shadow: -1px 0 2px 0px $color-grey-6 inset;
    transition: all 150ms ease-in-out;

    &:hover {
      background-color: $color-blue-2;
    }

    &:active {
      background-color: $color-blue-1;
    }

    &:focus {
      box-shadow: 0 0 10px 0px $color-blue-1 inset;
      z-index: 10;
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &--active {
      &:hover {
        background-color: $color-blue-1;
      }

      &:active {
        background-color: $color-blue-1;
      }

      background-color: $color-blue-1;
    }
  }
}
