@import "../../scss//colors.scss";

.product-paragraph {
  &__text {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
  }

  &__button {
    display: none;
  }
}

@media (max-width: 480px) {
  .product-paragraph {
    &__text {
      height: 120px;
      overflow: hidden;

      &-open {
        height: auto;
        overflow: visible;
      }
    }

    &__button {
      cursor: pointer;
      max-width: 80px;
      padding-top: 16px;
      font-size: 14px;
      color: $color-green-1;
      display: block;
      border-bottom: 1px dotted $color-green-1;
    }
  }
}
