@import "../../../Common/vars.scss";

.about {
  max-width: 840px;
  margin-left: 288px;

  .header {
    h1 {
      margin-top: 0;
    }
    h2 {
      margin-top: 88px;
    }

    h3 {
      margin-top: 32px;
      line-height: 1.4;
      font-size: 18px;
      color: #323232;
    }

    &_title {
      font-size: 32px;
      font-family: "PT Sans Bold";
      margin: 0 0 32px !important;
      color: $HEADER_COLOR;
      font-weight: 900;
    }
  }

  p {
    margin-bottom: 0;
    font-size: 14px;

    strong {
      font-weight: bold;
    }
  }

  ul {
    margin-top: 16px;
    font-family: $AG_HELVETICA;

    li {
      margin-bottom: 16px;
      padding-left: 16px;
      line-height: 24px;
      position: relative;
      font-size: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      &:after {
        position: absolute;
        content: "";
        width: 8px;
        height: 2px;
        background: $BORDER_COLOR;
        left: 0;
        top: 12px;
      }
    }
  }

  .questions {
    &__item {
      padding: 32px 40px;
      border-radius: 4px;
      margin-bottom: 8px;
      background: $WHITE;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;

      &-title {
        font-size: 16px;
        max-height: 24px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: $MENU_TXT_COLOR;
      }
    }

    &-list {
      padding: 16px 0 47px;
      border-bottom: 1px solid $BB_COLOR;
    }

    .question-wrapper {
      .answer {
        display: none;
      }

      &--active {
        .answer {
          display: flex;
          border-radius: 0px 0px 4px 4px;
          padding: 40px 40px 80px;
          margin-bottom: 8px;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          flex-direction: column;

          .title {
            margin-bottom: 32px;
          }
        }

        .questions__item {
          margin-bottom: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          &-title {
            display: flex;
            max-height: 100%;
            -webkit-line-clamp: none;
          }
        }
      }
    }
  }

  a {
    color: $LINK_COLOR;

    &:hover {
      color: $BUTTON_HOVER;
    }

    &:active {
      color: $BUTTON_ACTIVE;
    }
  }

  .selected {
    line-height: 0;
    height: 14px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
  }
}

@media (max-width: 1919px) {
  .about {
    margin-left: 50px;
  }
}
@media (max-width: 1025px) {
  .about {
    max-width: 526px;
    margin-left: 0px;
  }
}
@media (max-width: 639px) {
  .about {
    max-width: 100%;

    .header h2 {
      font-size: 18px;
      line-height: 28px;
      margin: 48px 0 24px;
    }

    .questions {
      &-list {
        padding: 0 0 32px;

        .header h3 {
          font-size: 16px;
          margin: 32px 0 48px;
        }
      }
      .question-wrapper {
        &:after {
          top: 16px;
          right: 16px;
        }

        &--active {
          .answer {
            padding: 24px 16px 32px;
          }
        }
      }

      .questions__item {
        display: flex;
        align-items: center;
        font-weight: normal;

        text-transform: none;
        padding: 16px 44px 16px 16px;

        &-title {
          font-size: 14px;
          -webkit-line-clamp: 2;
          max-height: 48px;
        }
      }
    }

    .header_title {
      font-size: 24px;
      margin-bottom: 40px;
    }
  }
}
