.dummy-shop-photo {
  border-radius: 8px 8px 0 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../../Common/images/chatPattern.png");

  &__text {
    text-align: center;
    color: white;
    font-size: 20px;
  }
}