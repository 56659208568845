$color-black-1: #444;
$color-black-2: #1e1e1e;
$color-black-3: #00000014;

$color-white-1: #ffffff;
$color-white-2: #fbfbfb;

$color-blue-1: #b8ddff;
$color-blue-2: #b8ddff4b;

$color-grey-1: #f6f6f6;
$color-grey-2: #c4c4c4;
$color-grey-3: #e5e5e5;
$color-grey-4: #ebebeb;
$color-grey-5: #cacaca;
$color-grey-6: #e0e0e0;
$color-grey-7: #e5e5e5;
$color-grey-8: #efefef;
$color-grey-9: #ababab;
$color-grey-10: #a8a8a8;

$color-green-1: #55bb31;
$color-green-2: #d7ded5;
$color-green-3: #82e900;
$color-green-4: #47a725;
$color-green-5: #3a9c18;
$color-green-6: #006419;

$color-red-1: #ff6969;
$color-red-2: #ea4646;
$color-red-3: #da3737;
$color-red-4: #f13c3c;
