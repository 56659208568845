@import "../../../Common/vars.scss";

.completed-vote {
  width: calc(100% - 616px);
  padding: 0 308px 88px;

  .item__status.finished {
    position: relative;
    display: inline-block;
    margin: 28px 0;

    &:not(:first-of-type) {
      margin-left: 20px;
    }
  }
  .tile-date {
    margin-bottom: 32px;
    position: relative;
    padding-bottom: 16px;
    color: $MENU_TXT_COLOR;
    .line {
      height: 4px;
      border-radius: 2px;
      position: absolute;
      bottom: -16px;
      z-index: 2;
      &.close {
        background: $CLOSE_TO_END_TIME;
      }
      &.middle {
        background: $MIDDLE_TIME;
      }
      &.green {
        background: $GREEN_LINE;
      }
    }
    .default-line {
      position: absolute;
      height: 4px;
      background: $DEFAULT_LINE;
      width: 100%;
      bottom: -16px;
    }
    &-start {
      position: absolute;
      left: 0;
      color: $DISABLED_COLOR;
    }
    &-end {
      position: absolute;
      right: 0;
      color: $DISABLED_COLOR;
    }
  }

  .header {
    position: relative;
    h1 {
      margin-top: 16px;
    }
    h4 {
      color: $TILE_HEADER_COLOR;
    }

    &-answers {
      color: $HINTS_COLOR;
    }

    .number,
    .question {
      display: inline-block;
      font-size: 18px;
    }

    .number {
      font-weight: bold;
      margin-right: 8px;
    }

    .variant {
      display: flex;
      margin-top: -16px;
      margin-bottom: 24px;
      color: $HINTS_COLOR;
      font-size: $DEFAULT_TEXT_SIZE;
    }
  }

  .answer-item {
    .line {
      height: 8px;
      background: #e9d100;
      border-radius: 8px;
      margin-bottom: 24px;

      &.user-chooze {
        background: #006419;
      }
    }
  }

  .votersnumbers {
    margin-top: 8px;
    .numbers {
      font-size: 32px;
      line-height: 40px;
      color: $CHOOZEN;
    }
    padding-bottom: 47px;
    border-bottom: 1px solid $DEFAULT_LINE;
    margin-bottom: 48px;
  }

  .answer-text {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    text-transform: uppercase;
  }

  .answer-voters {
    font-size: $DEFAULT_TEXT_SIZE;
    line-height: 24px;
    display: flex;
    margin-bottom: 8px;
  }
}

@media (max-width: 1200px) and (min-width: 640px) {
  .completed-vote {
    width: calc(100% - 248px);
    padding: 0 124px 88px;
  }
}

@media (max-width: 639px) {
  .completed-vote {
    width: calc(100% - 48px);
    padding: 0 24px 88px;
  }
}
