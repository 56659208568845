@import "../../../Common/vars.scss";

.SupportChat {
  position: fixed;
  right: 0px;
  top: 112px;
  bottom: 0;
  display: flex;
  box-shadow: 0px 4px 8px $SHADOW_BG_GREY;
  z-index: 2;
  overflow: hidden;
  transition: 0.1s;

  &__open {
    transition: 0.1s;
    cursor: pointer;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: $MAIN_CONTAINER_BG;
    border-radius: 8px 0 0 8px;
  }

  &__btn {
    width: 56px;
    height: 56px;
    border-width: 2px;
    border-radius: 50%;
    transition: 0.3s;
  }

  .chat {
    position: fixed;
    top: 102px;
    right: -660px;
    bottom: 0;
    display: block;
    overflow: hidden;
    border-radius: 8px 0 0 8px;
    background: $CHAT_BG;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    transition: 0.3s;
    width: 660px;
    box-shadow: $CHAT_SHADOW;
  }

  button {
    padding: 0;
  }
}

.SupportChat.active {
  box-shadow: -8px 0px 80px $SHADOW_BG_GREY;
  border-radius: 8px 0 0 8px;

  .chat {
    right: 0;
  }

  .SupportChat {
    &__open {
      width: 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .SupportChat {
    .chat {
      width: 340px;
      top: 88px;
    }
  }

  .SupportChat {
    &__open {
      display: none;
    }
  }
}

@media screen and (max-width: 639px) {
  .SupportChat {
    top: 0;
    right: 0;
    bottom: 0;
    left: 100%;

    .chat {
      top: 0;
      right: 0;
      left: 100%;
      bottom: 0;
      width: 100%;
    }
  }

  .SupportChat.active {
    z-index: 9999;

    left: 0;

    .chat {
      left: 0;
    }
  }

  .SupportChat {
    &__open {
      display: none;
    }
  }
}
