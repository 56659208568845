@import "../../scss//colors.scss";

.NavigationMenu {
  display: flex;
  gap: 30px;

  &__link {
    text-transform: uppercase;
    color: $color-black-1;
    font-size: 14px;
    padding: 36px 0 38px;

    &--active {
      color: $color-green-6;
    }

    &:focus {
      outline: none;
    }
  }
}
