.reward-store {
  width: 100%;

  &__content {
    padding-top: 48px;
  }

  &__list {
    padding-top: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
    grid-gap: 24px;
  }
}

@media (max-width: 830px) {
  .reward-store {
    .tabs {
      display: none;
    }
  }
}
