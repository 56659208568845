@import "../../../Common/vars.scss";

.sticky-news-section {
  &__sticky {
    position: sticky;
    top: 150px;
    max-width: 768px;
    height: calc(100vh - 250px);
    margin-right: 25px;
  }

  &__not-sticky {
    margin-top: 15px;
  }

  &__news {
    max-width: 696px;
    margin-right: 68px;

    & > *:not(.FileViewer-popup) {
      max-width: 696px;
    }
    & > .footerWrapper {
      background-color: inherit;
      &::after {
        display: none;
      }
    }
  }
  &__title {
    font-size: 32px !important;
    font-family: "PT Sans Bold" !important;
    margin: 0 0 82px;
    color: $HEADER_COLOR !important;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  &__thumb-vertical {
    position: relative;
    display: block;
    width: 100px;
    height: 100px;
    cursor: pointer;
    border-radius: inherit;
    background-color: #55bb31;
  }

  &__track-vertical {
    position: absolute;
    width: 6px;
    display: block !important;
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;
    background-color: #d7ded5;
  }
  .filesLinks > a {
    max-width: 100%;
    overflow-wrap: break-word;
  }
}
@media (max-width: 639px) {
  .sticky-news-section {
    // назначаю стили потомкам Scrollbars
    &__not-sticky {
      & > * {
        & > * {
          position: relative !important;
          margin-right: 0;
          margin-bottom: 0;
        }
      }
    }
    &__sticky {
      position: relative;
      top: 0;
      max-width: 100%;
      height: none;
      margin-right: 0;
      overflow: visible;
    }
    &__news {
      margin-right: 0;
    }
    &__thumb-vertical {
      display: none !important;
    }

    &__track-vertical {
      display: none !important;
    }
  }
}
