// Цвета
@import "./colors.scss";

//Шрифты
@import "./fonts.scss";

body {
  max-width: 100vw;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  background-color: $color-grey-1;
}

.app {
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
  font-family: $PT_SANS;
}

.Container {
  width: 100%;
  padding: 120px 24px 80px;
}
