@font-face {
    font-family: 'PT Sans BoldItalic';
    src: url('./PTSans-BoldItalic.eot');
    src: url('./PTSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./PTSans-BoldItalic.woff') format('woff'),
        url('./PTSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'PT Sans Italic';
    src: url('./PTSans-Italic.eot');
    src: url('./PTSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('./PTSans-Italic.woff') format('woff'), url('./PTSans-Italic.woff') format('woff'),
        url('./PTSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'PT Sans';
    src: url('./PTSans-Regular.eot');
    src: url('./PTSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('./PTSans-Regular.woff') format('woff'), url('./PTSans-Regular.woff') format('woff'),
        url('./PTSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PT Sans Bold';
    src: url('./PTSans-Bold.eot');
    src: url('./PTSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('./PTSans-Bold.woff') format('woff'), url('./PTSans-Bold.woff') format('woff'),
        url('./PTSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI Bold';
    src: url('./SegoeUI-Bold.eot');
    src: url('./SegoeUI-Bold.eot?#iefix') format('embedded-opentype'),
        url('./SegoeUI-Bold.ttf') format('truetype'), url('./SegoeUI-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI Semibold';
    src: url('./SegoeUI-SemiBold.eot');
    src: url('./SegoeUI-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./SegoeUI-SemiBold.ttf') format('truetype'), url('./SegoeUI-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('./SegoeUI.eot');
    src: url('./SegoeUI.eot?#iefix') format('embedded-opentype'),
        url('./SegoeUI.ttf') format('truetype'), url('./SegoeUI-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('./HelveticaNeue.eot');
    src: url('./HelveticaNeue.eot?#iefix') format('embedded-opentype'),
        url('./HelveticaNeue.ttf') format('truetype'), url('./HelveticaNeue.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'AG_Helvetica';
    src: url('./AGHelvetica.eot');
    src: url('./AGHelvetica.eot?#iefix') format('embedded-opentype'),
        url('./AGHelvetica.woff2') format('woff2'),
        url('./AGHelvetica.woff') format('woff'),
        url('./AGHelvetica.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}