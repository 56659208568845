@import "../../vars.scss";

.MobileLinkToProfile.active {
  box-shadow: inset 4px 0px 2px $SHADOW_BG_GREY;
}

.ProfileMobileMenu {
  z-index: 9999;
  background: $WHITE;
  width: calc(100% - 48px);
  transition: 0.3s;

  position: fixed;
  top: 0;
  bottom: 0;
  left: -100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__img {
    position: relative;
    .Avatar {
      width: 64px;
      height: 64px;
      font-size: 18px;
    }

    button {
      position: absolute;
      right: -46px;
      padding: 10px;
      top: 0;
      bottom: 0;
    }
  }

  &__links {
    margin-top: 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid $DEFAULT_LINE;
    width: calc(100% - 48px);

    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__item {
    text-transform: uppercase;
    font-size: $DEFAULT_TEXT_SIZE;
    color: $MENU_TXT_COLOR;
    position: relative;

    &.link {
      &__notification {
        &::after {
          content: "";
          position: absolute;
          right: -44px;
          top: -6px;
          width: 24px;
          height: 24px;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23FF9900'/%3E%3Cpath d='M15.9716 14.599L15.2848 13.5247C14.9676 13.0288 14.8 12.4608 14.8 11.8827V10.9416C14.8 9.75349 13.9552 8.74857 12.7999 8.42461V7.75079C12.7999 7.33672 12.4411 7 11.9999 7C11.5586 7 11.1998 7.33672 11.1998 7.75079V8.42461C10.0446 8.74857 9.19976 9.75349 9.19976 10.9416V11.8827C9.19976 12.4608 9.03215 13.0284 8.71534 13.5243L8.02852 14.5987C7.99132 14.6569 7.99051 14.7289 8.02612 14.7879C8.06172 14.8468 8.12773 14.8832 8.19973 14.8832H15.8C15.872 14.8832 15.9384 14.8468 15.974 14.7882C16.0096 14.7297 16.0084 14.6568 15.9716 14.599Z' fill='white'/%3E%3Cpath d='M10.7368 15.2774C10.9631 15.703 11.442 16 12 16C12.558 16 13.0369 15.703 13.2632 15.2774H10.7368Z' fill='white'/%3E%3C/svg%3E ");
        }
      }

      &__new-message {
        &::after {
          content: "";
          position: absolute;
          right: -38px;
          top: -7px;
          width: 30px;
          height: 30px;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_383_2317)'%3E%3Ccircle cx='32' cy='28' r='28' fill='%23FF9900'/%3E%3Ccircle cx='32' cy='28' r='27' stroke='%23E9D100' stroke-width='2'/%3E%3C/g%3E%3Cpath d='M25.6207 37H24.3453L25.2472 36.0895C25.7334 35.5986 26.0371 34.9601 26.1187 34.2674C24.0364 32.8878 23 30.8892 23 28.7939C23 24.9283 26.5191 21 32.0305 21C37.8692 21 41 24.6148 41 28.4552C41 32.321 37.8361 35.9333 32.0305 35.9333C31.0135 35.9333 29.9523 35.7963 29.0048 35.5448C28.1079 36.4735 26.8926 37 25.6207 37Z' fill='%23FBFBFB'/%3E%3Cdefs%3E%3Cfilter id='filter0_d_383_2317' x='0' y='0' width='64' height='64' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='4'/%3E%3CfeGaussianBlur stdDeviation='2'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_383_2317'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_383_2317' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
        }
      }
    }

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &__stats {
    margin: 8px 24px 24px;
    font-size: $DEFAULT_TEXT_SIZE;
    color: $SUPPORT_COLOR_TXT;

    .green {
      color: $DARK;
    }
  }

  & > button {
    width: calc(100% - 48px);
    height: 40px;

    &:last-child {
      margin-top: 8px;
    }
  }

  &.active {
    left: 0;
  }
}

@media screen and (min-width: 640px) {
  .ProfileMobileMenu {
    display: none;
  }
}
