@import "../../../Common/vars.scss";

.topmenu {
  height: 112px;
  display: flex;
  width: 100%;
  padding: 5px 0;
  justify-content: center;
  box-shadow: 0px 0px 6px 0px #bdb8b8;
  background-color: $MENU_BG;
  background-image: url("../../../Common/images/pattern.png");
  background-position: 50% 50%;
  background-repeat: repeat;

  .logo {
    svg {
      width: 36px;
      height: 32px;
    }
  }

  position: fixed;
  z-index: 1001;
  top: 0;

  &__auth {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;

    button {
      max-width: 192px;
      min-width: 140px;
      width: auto;
      flex-grow: 1;
    }
  }

  .menu-container {
    width: 100%;
    display: flex;
    max-width: 1704px;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 108px;
    align-items: center;

    .menu-items {
      display: flex;
      flex: 1 0 auto;
      margin-left: 32px;
      flex-shrink: 1;

      &__arrow {
        width: 12px;
        height: 7px;
        margin-left: 10px;
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.99746 4.64774L10.4868 0.190257C10.6101 0.0675196 10.775 -1.46077e-08 10.9508 -1.25112e-08C11.1267 -1.04136e-08 11.2915 0.0675197 11.415 0.190257L11.8082 0.580846C11.9319 0.70339 12 0.867201 12 1.04177C12 1.21633 11.9319 1.37995 11.8082 1.50259L6.46322 6.80993C6.33932 6.93306 6.17376 7.00048 5.99776 7C5.82098 7.00048 5.65561 6.93316 5.53161 6.80993L0.191805 1.50753C0.0680972 1.38489 6.89107e-08 1.22127 7.09935e-08 1.04661C7.30751e-08 0.872045 0.0680972 0.708427 0.191805 0.58569L0.584975 0.195197C0.840878 -0.0588986 1.25746 -0.0588986 1.51327 0.195197L5.99746 4.64774Z' fill='%2355BB31'/%3E%3C/svg%3E%0A");
      }

      &__item {
        font-family: $AG_HELVETICA;
        color: $MENU_TXT_COLOR;
        padding: 44px 10px;
        text-decoration: none;
        text-transform: uppercase;
        font-size: $DEFAULT_TEXT_SIZE;
        outline: none;

        &.active {
          color: $ACTIVE_MENU_LINK;
        }
      }
    }
  }
}

@media screen and (min-width: 1025px) {
  .topmenu {
    .menu-container {
      margin: 0 44px;

      .menu-items {
        &__item {
          padding: 44px 26px;
        }

        &__more {
          padding-right: 48px;
          z-index: 100;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .topmenu {
    height: 88px;
    background-image: none;
    padding: 0;

    .logo {
      padding: 28px 0;
    }

    &__auth {
      button {
        max-width: 160px;
        height: 40px;
        width: auto;
        flex-grow: 1;
      }
    }

    .menu-container {
      align-items: center;
      margin: 0 44px;

      .menu-items {
        min-height: 88px;
        display: flex;
        align-items: center;

        &__item {
          padding: 0 16px;
        }
      }
    }
  }
}

@media (max-width: 639px) {
  .topmenu {
    display: none;
  }
}
