@import "../../scss//colors.scss";

.NavigationMenuMore {
  min-width: 130px;
  display: flex;

  &-active {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
    position: relative;
    z-index: 2;
  }
  &__button {
    background: transparent;
    border: none;
    font-size: 14px;
    padding: 34px 82px 36px 16px;
    padding-right: 34px;
    text-transform: uppercase;
    position: relative;

    &-icon {
      position: absolute;
      top: 45%;
      left: 60px;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.99704 5.31171L12.2346 0.217436C12.3785 0.0771651 12.5709 -1.70423e-08 12.776 -1.45964e-08C12.9812 -1.21492e-08 13.1734 0.0771651 13.3175 0.217436L13.7762 0.663824C13.9206 0.803874 14 0.991087 14 1.19059C14 1.39009 13.9206 1.57708 13.7762 1.71724L7.54042 7.78278C7.39587 7.9235 7.20272 8.00055 6.99738 8C6.79114 8.00055 6.59821 7.92361 6.45354 7.78278L0.223772 1.72289C0.0794469 1.58273 7.8755e-08 1.39574 8.11354e-08 1.19612C8.35144e-08 0.996623 0.0794469 0.809631 0.223772 0.66936L0.682471 0.223083C0.981024 -0.0673124 1.46704 -0.0673124 1.76548 0.223083L6.99704 5.31171Z' fill='%2355BB31'/%3E%3C/svg%3E%0A");
      display: block;
      width: 14px;
      height: 8px;
    }

    &-active {
      padding: 34px 80px 36px 16px;

      .NavigationMenuMore__button-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.99704 2.68829L12.2346 7.78256C12.3785 7.92283 12.5709 8 12.776 8C12.9812 8 13.1734 7.92283 13.3175 7.78256L13.7762 7.33618C13.9206 7.19613 14 7.00891 14 6.80941C14 6.60991 13.9206 6.42292 13.7762 6.28276L7.54042 0.217219C7.39587 0.0765045 7.20272 -0.000550509 6.99738 3.04961e-06C6.79114 -0.000550504 6.59821 0.0763938 6.45354 0.217219L0.223772 6.27711C0.0794469 6.41727 7.8755e-08 6.60426 8.11354e-08 6.80388C8.35144e-08 7.00338 0.0794469 7.19037 0.223772 7.33064L0.682471 7.77692C0.981024 8.06731 1.46704 8.06731 1.76548 7.77692L6.99704 2.68829Z' fill='%2355BB31'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 75%;
    left: 0;
    width: calc(100% - 16px);
    padding-left: 16px;
    padding-top: 10px;
    background-color: $color-green-2;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.16);
    z-index: 1;
  }

  &__link {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    color: #444444;
  }
}
