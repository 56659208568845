$PT_SANS: "PT Sans";
$PT_SANS-BOLD: "PT Sans Bold";
$PT_SANS-ITALIC: "PT Sans Italic";
$PT_SANS-BOLDITALIC: "PT Sans BoldItalic";

@font-face {
  font-family: "PT Sans";
  src: url("../assets/fonts/PTSans-Regular.eot");
  src: url("../assets/fonts/PTSans-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/PTSans-Regular.woff") format("woff"),
    url("../assets/fonts/PTSans-Regular.woff") format("woff"),
    url("../assets/fonts/PTSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PT Sans Bold";
  src: url("../assets/fonts/PTSans-Bold.eot");
  src: url("../assets/fonts/PTSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/PTSans-Bold.woff") format("woff"),
    url("../assets/fonts/PTSans-Bold.woff") format("woff"),
    url("../assets/fonts/PTSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "PT Sans BoldItalic";
  src: url("../assets/fonts/PTSans-BoldItalic.eot");
  src: url("../assets/fonts/PTSans-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/PTSans-BoldItalic.woff") format("woff"),
    url("../assets/fonts/PTSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "PT Sans Italic";
  src: url("../assets/fonts/PTSans-Italic.eot");
  src: url("../assets/fonts/PTSans-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/PTSans-Italic.woff") format("woff"),
    url("../assets/fonts/PTSans-Italic.woff") format("woff"),
    url("../assets/fonts/PTSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
