@import "../../../Common/vars.scss";

.news-rubrics {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  gap: 10px;

  * > div {
    flex: 1;
  }

  &__button {
    font-family: $AG_HELVETICA;
    color: #444444;
    font-size: 16px;
    background: $MAIN_CONTAINER_BG;
    border: 1px solid $BB_COLOR;
    color: $MENU_TXT_COLOR;
    padding: 1px 24px 1px 36px;
    height: 36px;
    line-height: 36px;
    margin-right: 8px;
    cursor: pointer;
    position: relative;
    border-radius: 4px;

    &-alt {
      box-shadow: 0px 2px 4px 0px $SHADOW_BG_GREY;
      width: 96px;
      height: 40px;
      border-radius: 4px;
      border: none;
      transition: 200ms;
      background-color: $WHITE;
      font-size: 14px;
      color: $CHOOZEN;

      &:hover {
        opacity: 0.7;
      }

      &:active {
        opacity: 0.6;
      }

      &--active {
        border-radius: 4px 4px 0 0;
      }
    }

    &:after {
      position: absolute;
      content: "";
      width: 6px;
      height: 10px;
      border-bottom: 2px solid $DEFAULT_LINE;
      border-right: 2px solid $DEFAULT_LINE;
      left: 16px;
      transform: rotate(45deg);
      top: 10px;
    }

    &--active {
      background: $FILTER_ACTIVE_BG;
      border-color: $FILTER_ACTIVE_BG;

      &:after {
        border-color: #55bb31;
      }
    }

    &:hover {
      border: 1px solid $FILTER_HOVER;

      &:hover:after {
        border-bottom: 2px solid $WHITE;
        border-right: 2px solid $WHITE;
      }
    }
  }
  &__arrow-top {
    transform: rotate(180deg);
  }
}
