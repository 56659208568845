@import '../../../Common/vars.scss';

.violations {
  &-icon {
    float: right;
    position: relative;
    top: 2px;

    &-disabled {
      float: right;
      position: relative;
      top: 2px;

      path{
        fill: $DISABLED_COLOR;
      }
    }
  }
}
