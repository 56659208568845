.my-purchases {
  width: 100%;
  gap: 30px;
  display: flex;
  flex-direction: column;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__filter {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-text {
      font-size: 14px;
      color: #444;
    }
  }

  &__select {
    display: none;
  }

  .date-separator {
    font-family: "PT Sans";
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #a8a8a8;
    margin: 24px 0;

    &:first-child {
      margin-top: 0;
    }
  }
}

@media (max-width: 830px) {
  .my-purchases {
    &__list {
      gap: 8px;
    }

    &__tabs {
      display: none;
    }

    &__filter {
      display: none;
    }

    &__select {
      display: block;
    }
  }
}
