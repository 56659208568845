@import "../../../Common/vars.scss";

.answers-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 100%;

  .answer {
    background-color: $CARD_BG;
    padding: 20px 32px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border: 1px solid transparent;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &--active {
      border-color: #d5ffc7;
    }

    input {
      display: none;
    }

    &__text {
      font-family: $AG_HELVETICA_BOLD;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #323232;

      &--active {
        color: $CHOOZEN;
      }
    }

    &__button {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid $BB_COLOR;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -70%) rotate(-45deg);
        width: 20px;
        height: 12px;
        border-left: 3px solid $BB_COLOR;
        border-bottom: 3px solid $BB_COLOR;
      }

      &--active {
        background: rgba(224, 255, 213, 0.24);
        border: 1px solid #aad39c;

        &::after {
          border-color: #82e900;
        }
      }
    }
  }
}
