@import "../../vars.scss";

.submit-button {
  display: flex;
  background: $BUTTON_BG;
  height: 60px;
  width: 100%;
  justify-content: center;
  line-height: 56px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-family: $AG_HELVETICA;
  position: absolute;
  max-width: 342px;
  bottom: 140px;
}
