@import "../../../Common/vars.scss";

.profile-link {
  margin: 22px 0;
  padding: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 48px;
  font-family: $PT_SANS;
  border-radius: 24px;
  transition: 0.3s;
  font-weight: normal;

  img {
    z-index: 2;
  }

  &:hover &__username {
    color: $BUTTON_HOVER;
  }

  &__user-data {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__username {
    color: $MENU_TXT_COLOR;
  }

  &__points {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #444444;

    &--measure {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.02em;
      color: #808080;
    }
  }

  &__menu {
    transition: 0.3s;
    position: absolute;
    left: 0;
    right: 0;
    top: 56px;
    background: $WHITE;
    border-radius: 0 0 24px 24px;
    box-shadow: $SHADOW_MENU;
    padding-left: 64px;
    padding-right: 10px;
    padding-bottom: 24px;
    padding-top: 4px;
    flex-direction: column;
    opacity: 0;
    display: none;

    a {
      display: block;
      width: 100%;
      color: $MENU_TXT_COLOR;
      position: relative;
      font-size: 14px;

      &:hover {
        color: $BUTTON_HOVER;
      }

      &:active {
        font-size: $DEFAULT_TEXT_SIZE;
        color: $BUTTON_ACTIVE;
      }

      &:not(:last-of-type) {
        margin-bottom: 16px;
      }

      &.link {
        &__notification {
          &::after {
            content: "";
            position: absolute;
            right: 0px;
            top: 0px;
            width: 19px;
            height: 19px;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='9' fill='%23FF9900'/%3E%3Cpath d='M12.9716 11.599L12.2848 10.5247C11.9676 10.0288 11.8 9.4608 11.8 8.88271V7.9416C11.8 6.75349 10.9552 5.74857 9.7999 5.42461V4.75079C9.7999 4.33672 9.44109 4 8.99987 4C8.55864 4 8.19983 4.33672 8.19983 4.75079V5.42461C7.0446 5.74857 6.19976 6.75349 6.19976 7.9416V8.88271C6.19976 9.4608 6.03215 10.0284 5.71534 10.5243L5.02852 11.5987C4.99132 11.6569 4.99051 11.7289 5.02612 11.7879C5.06172 11.8468 5.12773 11.8832 5.19973 11.8832H12.8C12.872 11.8832 12.9384 11.8468 12.974 11.7882C13.0096 11.7297 13.0084 11.6568 12.9716 11.599Z' fill='white'/%3E%3Cpath d='M7.73684 12.2774C7.96308 12.703 8.44203 13 8.99999 13C9.55795 13 10.0369 12.703 10.2632 12.2774H7.73684Z' fill='white'/%3E%3C/svg%3E%0A");
          }
        }

        &__new-message {
          &::after {
            content: "";
            position: absolute;
            right: -1px;
            top: -2px;
            width: 22px;
            height: 22px;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_383_2317)'%3E%3Ccircle cx='32' cy='28' r='28' fill='%23FF9900'/%3E%3C/g%3E%3Cpath d='M25.6207 37H24.3453L25.2472 36.0895C25.7334 35.5986 26.0371 34.9601 26.1187 34.2674C24.0364 32.8878 23 30.8892 23 28.7939C23 24.9283 26.5191 21 32.0305 21C37.8692 21 41 24.6148 41 28.4552C41 32.321 37.8361 35.9333 32.0305 35.9333C31.0135 35.9333 29.9523 35.7963 29.0048 35.5448C28.1079 36.4735 26.8926 37 25.6207 37Z' fill='%23FBFBFB'/%3E%3Cdefs%3E%3Cfilter id='filter0_d_383_2317' x='0' y='0' width='64' height='64' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_383_2317' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
          }
        }
      }
    }
  }

  &.active {
    background: $WHITE;
    box-shadow: $SHADOW_MENU;
    border-radius: 24px 24px 0 0;

    button {
      transform: rotate(180deg);
    }

    .profile-link__username {
      color: $ACTIVE_MENU_LINK;
    }
  }

  &__menu.active {
    opacity: 1;
    display: flex;
  }
}

@media screen and (min-width: 1025px) {
  .profile-link {
    display: flex;
    min-width: 200px;
    &__user-data {
      margin-left: 12px;
      max-width: 120px;
    }

    &__username {
      display: block;
      text-decoration: none;
    }
    button {
      display: flex;
      margin-left: auto;
      padding: 12px;
    }
  }
}

@media screen and (min-width: 1001px) and (max-width: 1024px) {
  .profile-link {
    margin: 16px 0;

    &__user-data {
      margin-left: 12px;
    }

    &.active {
      border-top-left-radius: 24px;
      width: 168px;
    }

    &__menu {
      left: auto;
      padding: 20px 40px;
    }

    &::after {
      content: "";
      transition: 0.3s;
      position: absolute;
      right: 24px;
      height: 56px;
      width: 0;
      background: $WHITE;
      border-top-left-radius: 24px;
    }

    // &.active::after {
    //   right: 48px;
    //   width: 124px;
    // }
  }
}

@media screen and (min-width: 639px) and (max-width: 1000px) {
  .profile-link {
    margin: 16px 0;

    &__user-data {
      display: none;
    }

    &.active {
      border-top-left-radius: 0;
    }

    &__menu {
      min-width: 100px;
      left: auto;
      padding: 20px 32px 20px 40px;

      a {
        &.link {
          &__notification {
            &:after {
              right: -24px;
            }
          }

          &__new-message {
            &:after {
              right: -26px;
            }
          }
        }
      }
    }

    button {
      display: none;
      pointer-events: none;
      transition: 0.3s;
    }

    &::after {
      content: "";
      transition: 0.3s;
      position: absolute;
      right: 24px;
      height: 56px;
      width: 0;
      background: $WHITE;
      border-top-left-radius: 24px;
    }

    &.active::after {
      right: 48px;
      width: 124px;
    }
  }
}
