@import "../../scss/colors.scss";

.select {
  position: relative;

  &__placeholder {
    position: absolute;
    left: 18px;
    top: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    color: $color-grey-10;
  }

  .react-select {
    &__menu {
      z-index: 1000;
    }
    .react-select__control {
      background-color: $color-white-1;
      border-radius: 4px;
      border-color: transparent;
      cursor: pointer;
      padding-left: 4px;

      &:hover {
        border-color: $color-green-1;
      }

      &--is-focused {
        border-color: $color-green-1;
        box-shadow: none;
      }

      .react-select__value-container {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 26px;
        color: $color-black-1;
      }
    }

    .react-select__indicator-separator {
      display: none;
    }

    .react-select__indicator {
      path {
        fill: $color-green-1;
      }
    }

    .react-select__menu {
      .react-select__menu-list {
        box-shadow: 0 3px 4px 1px $color-grey-3;
        border: 1px solid $color-green-1;
        border-radius: 4px;

        .react-select__option {
          cursor: pointer;
          font-size: 14px;
          line-height: 26px;
          color: $color-black-1;

          &--is-selected {
            background-color: transparent;
          }

          &:hover {
            background-color: $color-grey-2;
          }
        }
      }
    }
  }
}
