@import "./vars.scss";

@mixin dash {
  &:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 16px;
    top: 10px;
    right: 0;
    margin: auto;
    background: $BB_COLOR;
  }
}

@mixin NEWS_CONTENT_TEXT {
  font-family: $PT_SANS;
  font-style: normal;
  font-weight: normal;
  font-size: $DEFAULT_TEXT_SIZE;
  line-height: $DEFAULT_LINE_HEIGHT;
  letter-spacing: $DEFAULT_LETTER_SPACING;
}