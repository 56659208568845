@import "../../vars.scss";

.chat {
  &__footer {
    background: $CHAT_ITEM;
    padding: 24px 48px 16px;
    box-shadow: 0px -2px 16px $CHAT_ITEM_SHADOW;
    border-radius: 0 0 0 8px;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;

    // & *:not(:last-child) {
    //   margin-right: 8px;
    // }
  }

  &__block-input {
    padding: 10px;
    margin: 0;
    color: $CHAT_DISABLED_TEXT;
  }

  &__addFile {
    width: 48px;
    height: 48px;
    background: $WHITE;
    border-radius: 4px;
    box-shadow: 0px 2px 4px $SHADOW_BG_GREY;

    &:hover {
      background: $ADDFILE_BG_HOVER;

      path {
        fill: $WHITE;
      }
    }

    &:active {
      background: $ADDFILE_BG_ACTIVE;
    }
  }

  &__input {
    width: 360px;
    min-height: 22px;
    box-shadow: 0px 1px 4px 0px $CHAT_ITEM_INNER_SHADOW inset;
    border: none;
    border-radius: 4px;
    outline: none;
    padding: 14px 16px 12px;
    resize: none;
    font-size: $DEFAULT_TEXT_SIZE;
    font-family: $PT_SANS;

    height: 22px;

    &::placeholder {
      color: $CHAT_DESC;
    }
  }

  &__send {
    background: $CHOOZEN;
    border-radius: 4px;
    box-shadow: 0px 2px 4px $SHADOW_BG_GREY;
    background: $DISABLED_BG;
    width: 96px;
    height: 48px;
    transition: 0.3s;

    &.active {
      background: $CHAT_ACTIVE;

      path {
        fill: $WHITE;
      }
    }
  }

  &__desc {
    color: $CHAT_DESC;
    font-size: $DEFAULT_TEXT_SIZE;
    margin-top: 12px;
    width: 490px;
    margin-left: 74px;
  }

  &__my-files {
    display: flex;
    max-height: 48px;
    margin-top: 16px;
    flex-grow: 1;
    margin-left: 74px;

    & > *:not(:last-child) {
      margin-right: 4px;
    }
  }

  &__file {
    height: 48px;
    width: 48px;

    svg {
      width: 16px;
      height: 16px;
    }

    .ShowFile {
      &__panel {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        justify-content: center;
      }
      &__delete {
        position: absolute;
        right: 4px;
        top: 4px;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        background: red;
        svg {
          width: 6px;
          height: 6px;

          path {
            fill: $WHITE;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .chat {
    &__footer {
      min-height: 78px;
      padding: 24px;
      align-items: flex-end;
    }

    &__send {
      width: 40px;
      height: 40px;
    }

    &__input {
      width: calc(100% - 80px);
      min-height: 24px;
      padding: 10px 16px 6px;
    }

    &__addFile {
      order: 1;
      width: 660px;
      margin-right: 0;
      margin-top: 8px;

      &:hover {
        background: $ADDFILE_BG_HOVER;
      }
    }

    & > *:not(:last-child) {
      margin-right: 0px;
    }

    &__footer > *:nth-child(2) {
      margin-right: 8px;
    }

    &__desc {
      display: none;
    }

    &__my-files {
      margin-left: 0;
      order: 2;
    }
  }
}

@media screen and (max-width: 639px) {
  .chat {
    &__footer {
      padding: 12px 24px;
      min-width: 102px;
    }

    &__input {
      font-size: $DEFAULT_MOBILE_TEXT_SIZE;
    }
  }
}
