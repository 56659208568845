@import "../../../Common/vars.scss";

.footer {
  display: flex;
  width: 100%;
  justify-content: center;
  background: $MENU_BG;
  flex-direction: column;
  flex: 0 0 auto;

  .container {
    cursor: default;
    max-width: 1704px;
    margin: 0 auto;
    width: 100%;
    align-items: center;
  }

  .logo {
    display: flex;
    margin-right: 32px;

    svg {
      width: 36px;
      height: 32px;
    }
  }

  .menu-container {
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 47px;
    padding-top: 88px;
    margin-bottom: 40px;
    align-items: center;

    .menu-items {
      display: flex;
      align-self: start;
      flex: 1 0 auto;

      &__item {
        font-family: $AG_HELVETICA;
        color: $MENU_TXT_COLOR;
        padding: 72px 26px;
        text-decoration: none;
        text-transform: uppercase;
        font-size: $DEFAULT_TEXT_SIZE;

        &--active {
          color: $ACTIVE_MENU_LINK;
        }
      }
    }
  }
  .link-container {
    display: flex;
    flex: 1 0;
    width: 100%;
    max-width: 1704px;
    align-self: center;
    margin-bottom: 20px;

    .link-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .link-item {
        font-family: $AG_HELVETICA;
        margin-bottom: 24px;
        padding-right: 33px;
        position: relative;

        &:first-child:after {
          position: absolute;
          content: "";
          height: 100%;
          width: 1px;
          background: $VERTICAL_LINE;
          right: 16px;
        }
      }
    }
  }
}

@media (max-width: 1792px) {
  .footer {
    .container {
      margin: 0 44px;
    }
  }
}

@media (max-width: 1024px) {
  .footer {
    .logo {
      display: none;
    }

    .container {
      margin: 0 44px;
    }

    .menu-container {
      padding-bottom: 47px;
      padding-top: 72px;
      margin-bottom: 36px;

      .menu-items {
        &__item {
          padding: 72px 24px 47px;

          &:first-child {
            padding-left: 0;
          }
        }
      }
    }

    .link-container .link-items {
      flex-direction: column;

      .link-item {
        &:first-child:after {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 639px) {
  .footer {
    padding: 48px 50px 104px 0;
    width: auto;

    .container {
      margin: 0 24px;
      padding-left: 0;
    }

    .menu-container {
      display: none;
    }

    .link-container {
      .link-items {
        .link-item {
          margin-bottom: 32px;

          &:first-child::after {
            display: none;
          }
        }
      }
    }

    .copyrights-container {
      margin-top: 72px;
      margin-bottom: 0;
    }
  }
}
