@import "../../../Common/vars.scss";
@import "../../../Common/mixins.scss";

.violation {
  margin: 0 auto;

  .component-date {
    display: flex;
    align-items: center;

    .number {
      padding-right: 24px;
      position: relative;

      @include dash;
    }

    .date {
      margin: 0 40px 0 8px;
    }
    .item__status {
      position: relative;
      left: 0;
      top: 0;
    }
  }

  .header {
    margin: 28px 0 24px 0;

    h1 {
      margin-top: 0;
      margin-bottom: 0;
    }
    h2 {
      margin-bottom: 0;
      color: #1e1e1e;
      font-weight: 900;
    }
  }

  .subcategory {
    p {
      padding-left: 48px;
      position: relative;

      &:after {
        position: absolute;
        content: "";
        height: 2px;
        width: 24px;
        background: $LINK_COLOR;
        left: 0;
        top: 12px;
      }
    }
  }

  .applicant {
    &-unit {
      padding-right: 24px;
      position: relative;

      @include dash;
    }
    .user {
      margin-left: 8px;
    }
  }

  .files-block {
    padding: 48px;
    box-shadow: $SHADOW;
    background: $POPUP_BG;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .header {
      margin: 0 0 24px 0;
    }

    .files {
      display: grid;
      // flex-direction: row;
      grid-gap: 24px;
      grid-template-columns: repeat(auto-fill, 160px);
      margin-bottom: 72px;
    }
  }

  .support-block {
    background: url("../../../Common/images/pattern.png") 50% 50% repeat;
    background-color: $POPUP_BG;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: $SHADOW;
    position: relative;

    .header {
      margin: 0;
    }

    .email {
      display: flex;
      margin-bottom: 16px;
      font-size: $DEFAULT_TEXT_SIZE;
    }

    .button {
      margin-bottom: 80px;
    }
  }
  .map-component {
    position: relative;
    z-index: 1;
  }

  .map-wrapper {
    z-index: 2;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      z-index: 2;
      pointer-events: none;
      box-shadow: inset 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    }
  }

  .radiobuttons-wrapper {
    flex-direction: row;
    display: flex;

    .container {
      display: flex;
      flex-direction: row;
      margin-right: 16px;
      .checkmark {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        left: 8px;
        right: 0;
        text-align: center;

        &:after {
          left: 0;
          right: 0;
          text-align: center;
        }
      }

      .text-label {
        color: $DEFAULT_CHECK_SUPPORT_TEXT_COLOR;
      }

      & .checkmark:after {
        width: 8px;
        height: 8px;
        transform: rotate(45deg);
        background: $ACTIVE_MENU_LINK;
        border-radius: 50%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-bottom: none;
        border-right: none;
      }

      & input:checked ~ .checkmark {
        border: 1px solid $CHOOZEN_BORDER;
        background: $WHITE;
      }

      .checkmark {
        position: absolute;
        border: 1px solid $DISABLED_COLOR;
        background: $BB_COLOR;
      }
    }
  }

  .close-btn {
    position: absolute;
    right: 0;
    top: 12px;
  }

  .close-btn-burger {
    display: none;
  }

  .support-block_popup {
    padding: 64px 0 40px;
    margin-bottom: 0px;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: $AG_HELVETICA_BOLD;
    text-transform: uppercase;
    text-align: center;
    background: 50% 50% no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='262' height='256' viewBox='0 0 262 256' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M205.608 110.409C204.62 107.367 201.926 105.213 198.751 104.925L155.451 100.987L138.338 60.8518C137.075 57.9031 134.2 56 131 56C127.8 56 124.924 57.9031 123.669 60.8518L106.556 100.987L63.2489 104.925C60.0742 105.218 57.386 107.372 56.3926 110.409C55.405 113.452 56.3171 116.789 58.7181 118.899L91.4499 147.651L81.7989 190.232C81.0928 193.363 82.3059 196.601 84.8992 198.479C86.2931 199.493 87.9307 200 89.5741 200C90.9864 200 92.3997 199.624 93.662 198.867L131 176.502L168.331 198.867C171.07 200.507 174.513 200.357 177.101 198.479C179.694 196.601 180.907 193.363 180.201 190.232L170.55 147.651L203.282 118.899C205.682 116.789 206.595 113.459 205.608 110.409Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0' y='0' width='262' height='256' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset/%3E%3CfeGaussianBlur stdDeviation='28'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.511 0 0 0 0 0.9125 0 0 0 0 0 0 0 0 0.24 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
    background-color: $POPUP_BG;
  }

  .support-block_result {
    padding: 84px 0 84px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: $AG_HELVETICA_BOLD;
    text-transform: uppercase;
    text-align: center;
    background: 50% 70% no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='262' height='256' viewBox='0 0 262 256' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M205.608 110.409C204.62 107.367 201.926 105.213 198.751 104.925L155.451 100.987L138.338 60.8518C137.075 57.9031 134.2 56 131 56C127.8 56 124.924 57.9031 123.669 60.8518L106.556 100.987L63.2489 104.925C60.0742 105.218 57.386 107.372 56.3926 110.409C55.405 113.452 56.3171 116.789 58.7181 118.899L91.4499 147.651L81.7989 190.232C81.0928 193.363 82.3059 196.601 84.8992 198.479C86.2931 199.493 87.9307 200 89.5741 200C90.9864 200 92.3997 199.624 93.662 198.867L131 176.502L168.331 198.867C171.07 200.507 174.513 200.357 177.101 198.479C179.694 196.601 180.907 193.363 180.201 190.232L170.55 147.651L203.282 118.899C205.682 116.789 206.595 113.459 205.608 110.409Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0' y='0' width='262' height='256' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset/%3E%3CfeGaussianBlur stdDeviation='28'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.511 0 0 0 0 0.9125 0 0 0 0 0 0 0 0 0.24 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
    background-color: $POPUP_BG;
  }

  .support-block {
    &__header {
      font-size: 18px;
      font-family: $AG_HELVETICA_BOLD;
      color: $HEADER_COLOR;
      margin-bottom: 0;
    }

    &__info {
      font-size: 16px;
      font-family: $AG_HELVETICA_BOLD;
      color: $CHOOZEN;
      margin-top: 16px;
    }
  }
  .result-block {
    padding: 50px 100px;
    margin-top: 16px;
    box-shadow: 1px 1px 4px 1px #cccccc;
    background: #fbfbfb;
    position: relative;

    &__header {
      position: relative;

      &-text {
        font-family: $AG_HELVETICA_BOLD;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #014a6c;
      }

      &-logo {
        width: 36px;
        height: 32px;
        position: absolute;
        left: -50px;
        top: 0;
      }
    }

    &__date {
      margin-top: 40px;

      &-from,
      &-to {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #006419;
        margin-right: 8px;
      }
    }

    &__comment {
      margin-top: 48px;

      &-header {
        font-family: $AG_HELVETICA_BOLD;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #1e1e1e;
      }
      &-text {
        margin-top: 24px;
        font-family: $AG_HELVETICA;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #1e1e1e;
      }
    }

    &__files {
      margin-top: 48px;

      &-header {
        margin-bottom: 24px;
        font-family: $AG_HELVETICA_BOLD;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #1e1e1e;
      }

      &-files {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        * > * {
          max-width: 192px;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .violation {
    .close-btn {
      position: relative;
      margin-bottom: 56px;
    }
  }
}

@media screen and (max-width: 639px) {
  .violation {
    .header h1 {
      font-size: 24px;
    }
    .header h2 {
      font-size: 18px;
    }

    &__user {
      display: flex;
      flex-direction: column;
      line-height: 1.4;

      span:first-child {
        margin-bottom: 8px;
      }
    }

    .files-block {
      padding: 25px;
      background: none;

      .files {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .item__status-text {
      display: none;
    }
    .item__status {
      width: 12px;
      height: 12px;
      padding: 10px;
    }

    .item__status::after {
      left: 32%;
      top: 35%;
    }

    .component-date {
      .item__status {
        margin-left: auto;
      }
    }

    button.close-btn-burger {
      display: flex;
      position: fixed;
      top: -50px;
      right: 0;
      left: 0;
      padding-top: 10px;
      height: 50px;
      width: calc(100% - 48px);
      background: $WHITE;
      border-radius: 0 0 8px 8px;
      z-index: 3;
      box-shadow: $SHADOW_MENU !important;
      transition: 0.2s;
      opacity: 0;

      &.fixed {
        opacity: 1;
        top: 126px;
      }
    }

    .result-block {
      margin-top: 0;
      padding: 48px 0px 0;
      background: transparent;
      box-shadow: none;

      &__header-logo {
        position: relative;
        top: 0;
        left: 0;
        margin-bottom: 32px;
      }
    }
  }
}
