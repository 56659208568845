@import "../../../Common/vars.scss";

.Statement {
  font-family: $AG_HELVETICA;
  max-width: 840px;
  width: 100%;
  padding: 48px;
  display: flex;
  flex-direction: column;
  box-shadow: $SHADOW;
  border-radius: 8px;
  background-color: $POPUP_BG;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-family: $AG_HELVETICA;
    font-size: 24px;
    color: $HEADER_COLOR;
  }

  &__content {
    color: $MENU_TXT_COLOR;
  }

  &__reset {
    background: none;
    outline: none;
    border: none;
    margin: 5px;
  }

  &__categories {
    margin-top: 40px;
  }
  &__subCategories {
    margin-top: 40px;
    animation: showOnSubCat 0.3s;
  }
  &__adress {
    margin-top: 40px;
  }

  &__dropzone {
    display: flex;
    flex-wrap: wrap;
    & > div {
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }

  &__map {
    margin-top: 28px;
    position: relative;

    .throbber-container {
      padding: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      height: auto;
      z-index: 2;
      background: rgba(0, 0, 0, 0.5);

      .throbber-svg {
        margin-top: 48px;
        height: calc(100% - 48px);

        .throbber-big {
          transform: translateY(-50px);
        }
      }

      .loader {
        top: 0px;
      }

      & * {
        color: $WHITE;
      }
    }
  }

  &__end {
    margin-top: 40px;
  }

  &__box-with-loader {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .Input__title {
    font-size: $DEFAULT_TEXT_SIZE;
    text-transform: uppercase;
    margin-bottom: 16px;
    margin-right: 20px;
    display: block;
  }

  .Input__loading {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 16px;
    display: block;
    color: $DEFAULT_CHECK_SUPPORT_TEXT_COLOR;
  }

  .map-component {
    position: relative;
    z-index: 1;
  }

  .map-wrapper {
    z-index: 2;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      z-index: 2;
      pointer-events: none;
      box-shadow: inset 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    }
  }
}

@media screen and (min-width: 640px) {
  .Statement {
    &__end {
      & > button:first-child {
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }
  }
}

@media screen and (max-width: 639px) {
  .Statement {
    padding: 0 0 24px;
    max-width: 640px;
    box-shadow: none;
    background: $MAIN_CONTAINER_BG;

    &__title {
      font-size: $BIGGER_TEXT_SIZE !important;
    }

    &__end {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      button {
        width: auto;
        margin-bottom: 8px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .Statement {
    &__title {
      font-size: $BIGGER_TEXT_SIZE !important;
    }
  }
}

@keyframes showOnSubCat {
  from {
    height: 0px;
    overflow: hidden;
  }
  to {
    height: 78px;
    overflow: visible;
  }
}
