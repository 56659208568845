.file-component {
  margin-top: 20px;
  display: flex;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    color: #006419;
  }

  &__icon {
    margin-right: 20px;
    display: flex;
    flex: 0 0 18px;
    height: 24px;
  }

  .file-component__text {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
}
