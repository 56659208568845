.was-became {
  &__files {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    * > * {
      max-width: 192px;
    }
  }

  &__file {
    display: flex;
    flex-direction: column;
  }
  &__dummy-wrapper {
    height: 160px;
    width: 160px;
  }
}
