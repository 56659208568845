@import "../../vars.scss";

.AuthWarning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 200px;

  h1 {
    color: $MENU_TXT_COLOR;
    font-family: $AG_HELVETICA_BOLD;
    margin-bottom: 34px;
    text-align: center;
  }

  button {
    width: 100%;
    max-width: 192px;
  }
}
