@import "../../../Common/vars.scss";

.map-component {
  width: 100%;
  height: 280px;
  overflow: hidden;

  .my-div-icon {
    width: 26px !important;
    height: 32px !important;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='32' viewBox='0 0 24 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 0C5.38316 0 0 5.19888 0 11.5891C0 19.5196 10.7388 31.1621 11.196 31.6539C11.6255 32.1158 12.3753 32.115 12.804 31.6539C13.2612 31.1621 24 19.5196 24 11.5891C23.9999 5.19888 18.6168 0 12 0ZM12 17.42C8.67091 17.42 5.96256 14.8043 5.96256 11.5891C5.96256 8.37401 8.67097 5.75838 12 5.75838C15.329 5.75838 18.0374 8.37407 18.0374 11.5892C18.0374 14.8043 15.329 17.42 12 17.42Z' fill='%23014A6C'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    bottom: 0;
    margin-left: -13px !important;
    margin-top: -32px !important;
  }

  .marker-popup {
    &__number {
      font-family: $AG_HELVETICA;
      font-size: 14px;
      line-height: 24px;
      color: $MENU_TXT_COLOR;
    }

    &__name {
      margin-top: 16px;
      font-family: $AG_HELVETICA;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: $TILE_HEADER_COLOR;
    }

    &__district {
      margin-top: 8px;
      font-family: $AG_HELVETICA;
      font-size: 14px;
      line-height: 24px;
      color: $MENU_TXT_COLOR;
    }

    &__support {
      margin-top: 8px;
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 24px;
      font-family: $AG_HELVETICA;
      color: $SUPPORT_COLOR_TXT;

      &-count {
        color: $SCROLLBAR_INCREMENT_COLOR;
      }
    }
  }
}
