@import "../../../Common/vars.scss";
.custom-field {
  margin-top: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  display: flex;
  background: #f6f6f6;
  flex-direction: column;

  &-header {
    padding: 22px 32px;
    min-height: 16px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__button {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid $BB_COLOR;
      position: relative;
      background-color: transparent;

      &-close {
        display: none;
      }

      &:before {
        position: absolute;
        content: "";
        width: 12px;
        height: 24px;
        border-bottom: 2px solid $BB_COLOR;
        border-right: 2px solid $BB_COLOR;
        right: 16px;
        top: 6px;
        transform: rotate(45deg);
      }

      &.true {
        border-color: $GREEN_LINE;

        &:before {
          border-bottom: 2px solid $GREEN_LINE;
          border-right: 2px solid $GREEN_LINE;
        }
      }
    }
  }

  .textarea-wrapper {
    margin: auto;
    margin-top: 32px;
    margin-bottom: 48px;
    border-radius: 8px;
    width: 100%;

    textarea {
      border: none;
      min-height: 104px;
      width: 100%;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 552px;
    align-self: center;
    padding: 0 16px 32px;

    .decline {
      background: $WHITE;
      color: $HEADER_COLOR;
      box-shadow: $SHADOW;
    }
  }

  &__isChosen {
    .custom-field-header {
      &__button {
        border-color: $GREEN_LINE;

        &:before {
          border-bottom: 2px solid $GREEN_LINE !important;
          border-right: 2px solid $GREEN_LINE !important;
        }
      }
    }
  }
}

@media (max-width: 639px) {
  .custom-field {
    text-align: center;

    &-header {
      flex-direction: column;
      padding: 16px;

      &__button {
        margin-top: 18px;
        width: 100%;
        height: 32px;
        border-radius: 8px;

        &:before {
          top: 0%;
          left: calc(50% - 10px);
          width: 12px !important;
          height: 20px;
          border-right: 3px solid #e5e5e5;
          border-bottom: 3px solid #e5e5e5;
        }
      }
    }

    .form {
      width: auto;
      padding: 0;
      padding: 0 16px 20px;
      align-self: auto;

      .button.accept,
      .button.decline {
        width: 100% !important;
        max-width: 100%;
        margin: 0;
        gap: 12px;
      }
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 12px;
    }

    .textarea-wrapper {
      padding: 0;
      margin-top: 0;
      margin-bottom: 24px;
    }

    &.true {
      margin-top: 0;
      padding: 0;
      z-index: 13;
      position: fixed;
      left: 0px;
      top: 0px;
      height: 100%;
      background-color: #f6f6f6;
      right: 48px;
      overflow-y: scroll;

      .custom-field-header {
        padding: 26px 24px;
        display: flex;
        flex-direction: row;
        position: relative;

        &__button {
          display: none;

          &-close {
            position: absolute;
            display: block;
            width: 16px;
            height: 16px;
            border: none;
            right: 24px;
            top: 26px;
            z-index: 10;
            overflow: hidden;
            background-repeat: no-repeat;
            background-color: transparent;
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.88392 8.00004L15.8169 1.06698C16.061 0.822882 16.061 0.427133 15.8169 0.18307C15.5728 -0.0609922 15.1771 -0.0610234 14.933 0.18307L7.99998 7.11613L1.06697 0.18307C0.822881 -0.0610234 0.427132 -0.0610234 0.18307 0.18307C-0.060992 0.427164 -0.0610233 0.822914 0.18307 1.06698L7.11608 8L0.18307 14.9331C-0.0610233 15.1772 -0.0610233 15.5729 0.18307 15.817C0.305101 15.939 0.465069 16 0.625037 16C0.785006 16 0.944943 15.939 1.067 15.817L7.99998 8.88394L14.933 15.817C15.055 15.939 15.215 16 15.375 16C15.5349 16 15.6949 15.939 15.8169 15.817C16.061 15.5729 16.061 15.1771 15.8169 14.9331L8.88392 8.00004Z' fill='%2355BB31'/%3E%3C/svg%3E%0A");
          }
        }
      }

      .textarea-wrapper {
        textarea {
          min-height: 232px;
        }
      }

      .form {
        padding: 0 24px 40px;
      }
    }
  }
}
