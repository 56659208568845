@import "../../../Common/vars.scss";

.question-wrapper {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;

  .answer {
    &__title {
      text-transform: uppercase;
      font-size: 14px;
      margin-bottom: 32px;
      color: $MENU_TXT_COLOR;
    }

    p {
      font-size: 14px;
      color: $MENU_TXT_COLOR;
    }
  }

  position: relative;

  &:after {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    transform: rotate(-135deg);
    z-index: 1;
    right: 26px;
    top: 38px;
    border-top: 2px solid $BTN_PRIMARY;
    border-left: 2px solid $BTN_PRIMARY;
    transition: transform linear 0.3s;
    cursor: pointer;
  }

  &--active {
    &:after {
      transform: rotate(45deg);
    }
  }

  figure {
    img {
      width: 100%;
    }
  }
}

@media (max-width: 639px) {
  .question-wrapper {
    .answer__title {
      margin-bottom: 16px;
    }
  }
}
