@import "../../../Common/vars.scss";

.about-form {
  margin-top: 40px;
  padding: 72px 144px 80px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;

  &__button {
    margin-top: 40px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    &-title {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      font-family: $AG_HELVETICA_BOLD;
      margin: 0;
    }

    &-button {
      width: 16px;
      height: 16px;
      overflow: hidden;
      background: transparent;
      border: none;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.88392 8.00004L15.8169 1.06698C16.061 0.822882 16.061 0.427133 15.8169 0.18307C15.5728 -0.0609922 15.1771 -0.0610234 14.933 0.18307L7.99998 7.11613L1.06697 0.18307C0.82288 -0.0610234 0.427132 -0.0610234 0.18307 0.18307C-0.060992 0.427164 -0.0610233 0.822914 0.18307 1.06698L7.11608 8L0.18307 14.9331C-0.0610233 15.1772 -0.0610233 15.5729 0.18307 15.817C0.305101 15.939 0.465069 16 0.625037 16C0.785005 16 0.944943 15.939 1.067 15.817L7.99998 8.88394L14.933 15.817C15.055 15.939 15.215 16 15.375 16C15.5349 16 15.6949 15.939 15.8169 15.817C16.061 15.5729 16.061 15.1771 15.8169 14.9331L8.88392 8.00004Z' fill='%2355BB31'/%3E%3C/svg%3E%0A");
    }
  }

  .textarea-wrapper {
    textarea {
      background: inherit;
      box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.24);
      min-height: 104px;
      margin-bottom: 48px;
    }
  }

  .form {
    .accept,
    .decline {
      display: inline-block;
      max-width: 192px;
      width: 100%;
      margin: 0;
      margin-right: 8px;
      border: none;
    }

    .decline {
      background: $WHITE;
      color: $HEADER_COLOR;
      box-shadow: $SHADOW;
    }
  }
}

@media (max-width: 1024px) {
  .about-form {
    padding: 72px 80px 80px;
  }
}

@media (max-width: 639px) {
  .about-form {
    padding: 26px 24px 40px;
    position: fixed;
    z-index: 13;
    left: 0px;
    top: 0px;
    height: 100%;
    background-color: #f6f6f6;
    right: 48px;
    margin: 0;
    overflow-y: scroll;

    &__button {
      button {
        width: 100%;
      }
    }

    .form .button {
      max-width: 100%;
      margin-bottom: 8px;
    }

    .textarea-wrapper {
      textarea {
        margin-bottom: 24px;
      }
    }
  }
}
