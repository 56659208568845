@import "../../../Common/vars.scss";

.UserData {
  font-family: $AG_HELVETICA;
  max-width: 840px;
  width: 100%;
  padding: 48px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background-color: $WHITE;

  &__main {
    display: flex;
  }

  &-btn {
    display: none;
  }

  &__main > .Avatar-wrapper .Avatar {
    width: 120px;
    height: 120px;
    background: $IMG_BG;
    border-radius: 4px;
    margin-right: 48px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 34px;
    font-family: $AG_HELVETICA_BOLD;
    text-transform: uppercase;
    color: $MORE_BUTTON_BG;
    justify-self: center;
    letter-spacing: -1px;
  }

  &__name {
    display: flex;
    flex-direction: column;
    font-family: $AG_HELVETICA_BOLD;
    
    font-size: 24px;
    padding-top: 16px;

    h2 {
      color: #1E1E1E;
      font-weight: 900;
    }
  }

  &__birthday {
    margin-left: 168px;
  }

  &__section {
    margin-left: 168px;

    *:first-child {
      text-transform: uppercase;
    }

    &:not(:first-of-type) {
      margin-top: 24px;
    }
  }

  &__title {
    color: $SUPPORT_COLOR_TXT;
    margin-bottom: 10px;
    display: block;

    &-gender {
      text-transform:none !important;
    } 
  }

  &__info {
    color: $MENU_TXT_COLOR;
    display: block;

    &-bold {
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .map-wrapper {
    margin: 16px -24px 0px;
    position: relative;
    z-index: 1;

    & > div {
      z-index: 1;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      z-index: 2;
      pointer-events: none;
      box-shadow: inset 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    }
  }
}

@media screen and (max-width: 1023px) {
  .UserData {
    padding: 24px;
    max-width: 1024px;

    &__main {
      flex-direction: column;
    }

    &__main > .Avatar {
      margin-right: 0;
      margin-bottom: 16px;
    }

    &__section {
      margin: 0;
    }

    &__birthday {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 639px) {
  .UserData {
    background-color: $MAIN_CONTAINER_BG;
    box-shadow: none;
    padding: 0;

    &__name h2 {
      font-size: 16px;
    }

    &-btn {
      display: block;
      padding-top: 20px;
      display: block;
      justify-content: center;
      .Button {
        width: 100%;
      }
    }
  }
}
