@import "../../../Common/vars.scss";

.tile {
  background: $WHITE;
  padding: 48px 40px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  height: 252px;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;

  &.done {
    border: 2px solid $GREEN_LINE;
  }

  &-date {
    margin-bottom: 32px;
    position: relative;
    padding-bottom: 16px;
    color: $MENU_TXT_COLOR;

    &.line-close {
      .line {
        &.close {
          background: $DEFAULT_LINE;
        }
      }
      .tile-date-start,
      .tile-date-end {
        color: $DISABLED_COLOR;
        font-size: 14px;
      }
    }

    .line {
      height: 4px;
      border-radius: 2px;
      position: absolute;
      bottom: -16px;
      z-index: 2;
      &.close {
        background: $CLOSE_TO_END_TIME;
      }
      &.middle {
        background: $MIDDLE_TIME;
      }
      &.green {
        background: $GREEN_LINE;
      }
    }
    .default-line {
      position: absolute;
      height: 4px;
      background: $DEFAULT_LINE;
      width: 100%;
      bottom: -16px;
    }
    &-start {
      position: absolute;
      left: 0;
    }
    &-end {
      position: absolute;
      right: 0;
    }
  }

  .tile__content-wrapper {
    .tile-name {
      h3 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 84px;
        line-height: 28px;
      }
    }
  }

  &-name {
    color: $TILE_HEADER_COLOR;
    font-family: $AG_HELVETICA;
    font-weight: bold;
    font-size: $TILE_SIZE_TEXT;
    text-transform: uppercase;
    margin-bottom: 16px;
    line-height: 32px;
  }

  &-info span {
    margin-bottom: 16px;
    display: flex;
    display: inline-block;
    font-size: $DEFAULT_TEXT_SIZE;
  }

  &-info {
    .end-text {
      color: $STATUS_FINISHED;
      display: block;
    }
    .line {
      margin: 0 16px;
    }
    .number {
      color: $NUMBER_COLOR;
    }

    .question-text,
    .voted-text {
      padding-right: 32px;
      position: relative;

      &:after {
        position: absolute;
        content: "";
        width: 16px;
        height: 1px;
        background: $DEFAULT_LINE;
        top: 50%;
        left: 72px;
      }
    }

    .voted-text {
      &:after {
        top: 50%;
        left: 106px;
      }
    }
  }

  &.important {
    position: relative;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    order: -1;

    &.wphoto {
      grid-column: 1 / 3;
    }

    .tile-image {
      position: absolute;
      max-height: 348px;
      max-width: 264px;
      height: 100%;
      width: 100%;
      border-radius: 4px 0 0 4px;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto 0;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50% 50%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      // Для временной заглушки
      .dummy-shop-photo__text svg {
        display: none;
      }
    }
    .tile-name,
    .tile-date,
    .tile-desc,
    .tile-info {
      margin-left: 272px;
    }
  }
}

@media (max-width: 720px) {
  .tile.important {
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: 4px;

    .tile__content-wrapper {
      padding: 60px 16px 16px;
      padding-top: 60px;
    }

    .tile-name {
      font-size: 16px;
    }

    .tile-date,
    .tile-name,
    .tile-info,
    .tile-desc {
      margin-left: 0;
    }
    .tile-image {
      border-radius: 4px 4px 0 0;
      position: relative;
      height: 128px;
      max-width: 672px;
      width: 100%;
      margin: 0px 0px -40px;

      img {
        border-radius: 4px;
        position: absolute;
        width: 100%;
      }
    }
  }

  .tile {
    padding: 24px 16px;

    &:not(:first-child) {
      height: 248px !important;
    }

    .tile-name {
      font-size: 16px;
    }

    &-desc {
      display: none;
    }

    &-info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .line {
        display: none;
      }
    }
  }

  .votes .votes-list .tile {
    height: 248px !important;

    &.important {
      height: auto !important;
    }
  }
}
