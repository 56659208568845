@import "../../../Common/vars.scss";

.votes {
  margin-bottom: 88px;

  &-page {
    margin-bottom: 0;
  }

  &__dropdown {
    display: none;
  }

  .votes-list {
    display: grid;
    grid-template-columns: repeat(4, calc(25% - 18px));
    grid-gap: 24px;
    gap: 24px;
    padding-bottom: 10px;

    // .tile.important {
    //   //   grid-area: 1/1/2/3;
    // }
    .tile {
      display: flex;
      flex-direction: column;
      height: 348px !important;
    }
  }

  .button {
    display: inline-block;
    margin-right: 8px;
  }

  .header_title {
    font-size: 32px;
    font-family: "PT Sans Bold";
    margin: 0 0 32px;
    color: $HEADER_COLOR;
    font-weight: 900;
  }

  .clamp-lines {
    p {
      font-size: 15px;
    }
  }
}

@media (min-width: 1201px) and (max-width: 1500px) {
  .votes {
    & &-list {
      grid-template-columns: repeat(3, calc(33% - 13px));
    }
  }
}

@media (max-width: 1200px) and (min-width: 640px) {
  .votes {
    .votes-list {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 8px));
      grid-gap: 16px;
      .tile {
        &.small,
        &.middle,
        &.big,
        &.giant {
          flex: 0 1 45%;
        }
      }
    }
  }
}

@media (max-width: 639px) {
  .votes {
    margin-bottom: 64px;
    display: flex;
    flex-direction: column;

    &__filter {
      display: none;
    }

    &__dropdown {
      display: block;
      margin-bottom: 32px;
    }

    .votes-list {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .filter {
      flex-direction: column;
    }

    .header_title {
      font-size: 24px;
      margin-bottom: 40px;
    }

    .to {
      width: 100%;
      display: flex;
      justify-content: center;
      span {
        margin: 0;
      }
    }
  }
}
