.error-image {
  position: relative;
  width: fit-content;
  height: fit-content;

  &__status {
    position: absolute;
    top: 100px;
    left: 63px;
    font-family: "PT Sans";
    font-weight: 700;
    font-size: 95px;
    line-height: 124px;
    letter-spacing: 0.05em;
    color: #ffffff;
  }
}

@media screen and (max-width: 639px) {
  .error-image {
    svg {
      width: 200px;
      height: 200px;
    }

    &__status {
      top: 81px;
      left: 37px;
      font-size: 70px;
      line-height: 70px;
      letter-spacing: 0em;
    }
  }
}
