@import "../../../Common/vars.scss";

.news-page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 24px;

  &__container--news,
  &__container--rubrics {
    flex-grow: 1;
    flex-shrink: 0;
    transition: all 0.3s;
  }

  &__container--news {
    border-right: 1px solid #e5e5e5;
    z-index: 1;
  }
  &__container--rubrics {
    max-width: 840px;
  }

  &__rubrics {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(216px, 260px));
    grid-gap: 48px 24px;
  }

  &__linkBack-container {
    position: fixed;
    top: 80px;
    background-color: #f6f6f6;
    z-index: 11;
    width: 100%;
    padding: 16px 0px;
  }
  &__linkBack-container--offset {
    top: 160px;
  }
}

@media (max-width: 1919px) {
  .news-page {
    &__news {
      margin-right: 52px;
    }
    &__rubrics {
      grid-template-columns: repeat(auto-fit, 216px);
    }
  }
}

@media (max-width: 1025px) and (min-width: 640px) {
  .news-page {
    grid-template-columns: 8fr 2fr;

    &__rubrics {
      grid-template-columns: repeat(auto-fit, minmax(216px, 1fr));
    }
  }
}

@media (max-width: 639px) {
  .news-page {
    display: block;

    &__container--news {
      border-right: none;
    }
    &__container--rubrics {
      width: 100%;
    }
    // назначаю стили потомкам Scrollbars
    &__not-sticky {
      & > * {
        & > * {
          position: relative !important;
          margin-right: 0;
          margin-bottom: 0;
        }
      }
    }
    &__sticky {
      position: relative;
      top: 0;
      max-width: 100%;
      height: none;
      margin-right: 0;
      overflow: visible;
    }
    &__news {
      margin-right: 0;
    }
    &__rubrics {
      grid-template-columns: repeat(auto-fit, minmax(216px, 1fr));
    }
    .thumb-vertical {
      display: none !important;
    }

    .track-vertical {
      display: none !important;
    }
  }
}
