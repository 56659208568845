@import "../../vars.scss";

.input {
  &-wrapper {
    position: relative;
    display: flex;
    margin: 15px 0;
    width: 100%;

    input {
      display: flex;
      padding: 13px 16px;
      flex: 0 1 100%;
      border: 1px solid #fff;
      border-color: #fff;
      box-sizing: border-box;
      border-radius: 4px;
      background: #fff;

      &:hover {
        border-color: $HOVER;
      }

      &:focus {
        border-color: $ACTIVE;
      }
      }

      &__isEmpty {
        input {
          border: 1px solid $BORDER_COLOR;
          background-color: #f8f8f8 !important;
        }
    }
  }

  &-error {
    &__open-button {
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      border: 2px solid $INPUT_ERROR;
      border-radius: 50%;
      margin-right: 20px;
      cursor: pointer;
      background: none;

      &:after {
        position: absolute;
        top: 50%;
        left: 47%;
        transform: translate(-50%, -50%);
        transform: translateY(-50%);
        content: "";
        display: block;
        height: 1px;
        width: 2px;
        border-top: 10px solid $INPUT_ERROR;
        border-bottom: 3px solid $INPUT_ERROR;
      }

      &:active {
        opacity: 0.6;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 16px;
      top: -6px;
      width: 16px;
      height: 16px;
      background: $INPUT_ERROR;
      transform: rotate(45deg);
      transition: transform 0.3s;
    }
  }
}

@media (max-width: 639px) {
  .input {
    &-wrapper {
      input {
        padding: 9px 16px;
      }
    }
  }
}
