@import '../../vars.scss';

.MobileLinkToProfile {
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	width: 48px;
	background: $WHITE;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: -4px 0px 12px $SHADOW_BG_GREY;
	transition: .3s;

	path {
		fill: $BTN_PRIMARY;
	}

	// for button
	outline: none;
	border: none;
	
	&:active {
		background: $BTN_SUB_CLICK;

		& path {
			fill: $WHITE;
		}

		& .Avatar {
			background: $WHITE;
			color: $BTN_SUB_CLICK;
		}
	}
}

@media screen and (min-width: 640px) {
	.MobileLinkToProfile {
		display: none;
	}
}