@import "../../scss//colors.scss";

.history-item {
  background-color: $color-white-1;
  border-radius: 4px;
  display: flex;

  &__counts {
    max-width: 89px;
    width: 100%;
    border-right: 1px solid $color-grey-1;
    padding: 24px;
    display: flex;
    align-items: center;
  }

  &__sign {
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid $color-grey-3;
    margin-right: 16px;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &-writeoff {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='2' viewBox='0 0 14 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='14' height='2' rx='1' fill='%23F13C3C'/%3E%3C/svg%3E%0A");
    }

    &-refill {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 0C6.44772 0 6 0.447716 6 1V6H1C0.447716 6 0 6.44772 0 7C0 7.55228 0.447716 8 1 8H6V13C6 13.5523 6.44772 14 7 14C7.55228 14 8 13.5523 8 13V8H13C13.5523 8 14 7.55228 14 7C14 6.44772 13.5523 6 13 6H8V1C8 0.447716 7.55228 0 7 0Z' fill='%2355BB31'/%3E%3C/svg%3E%0A");
    }
  }

  &__count {
    font-size: 18px;
    font-weight: 900;

    &-refill {
      color: $color-green-1;
    }

    &-writeoff {
      color: $color-red-4;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 16px 16px 31px;
    font-size: 14px;

    &-action {
      color: $color-grey-10;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &-name {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

@media (max-width: 540px) {
  .history-item {
    flex-direction: column;

    &__counts {
      padding-bottom: 10px;
      border-right: none;
    }

    &__text {
      padding-top: 0;
      padding-left: 24px;
      gap: 10px;
    }
  }
}
