$PRIMARY: #55bb31;
$HOVER: #47a725;
$ACTIVE: #3a9c18;
$DARK: #006419;
$SECONDARY: #ff9900;
$SECONDARY_HOVER: #e78a00;
$SECONDARY_BORDER: #e9d100;
$SECONDARY_ACTIVE: #d37f00;
$SECONDARY_ACTIVE_BORDER: #e78a00;
$WHITE: #ffffff;

$TEXT_TITLE: #323232;

$BG_RADIOBUTTON: #6ca9d2;
$BG_CHECKBOX: #6ca9d2;
$FONT_FAMILY: "Helvetica Neue";
$BORDER_COLOR: #cdcfd1;
$BOX_SHADOW: #dee6ed;
$BUTTON_BG: #0063b0;
$LINK_COLOR: #55bb31;
$MENU_BG: #d7ded5;
$MENU_TXT_COLOR: #444444;
$CARD_BG: #ffffff;
$AG_HELVETICA: "PT Sans";
$AG_HELVETICA_BOLD: "PT Sans Bold";
$PT_SANS: "PT Sans";
$DEFAULT_TEXT_SIZE: 14px;
$DEFAULT_MOBILE_TEXT_SIZE: 16px;
$BIGGER_TEXT_SIZE: 16px;
$DEFAULT_LINE_HEIGHT: 24px;
$DEFAULT_LETTER_SPACING: 0.02em;
$BLACK_COLOR: #000;
$HEADER_COLOR: #1e1e1e;
$TABLE_HEAD_BG_COLOR: #e6e6e6;
$MAIN_CONTAINER_BG: #f6f6f6;
$WHITE: #ffffff;
$TILE_HEADER_COLOR: #323232;
$NEWS_PREVIEW_TEXT_SIZE: 16px;
$TILE_SIZE_TEXT: 18px;
$SHADOW_BG: #cccccc;
$SHADOW: 0px 4px 8px rgba(0, 0, 0, 0.08);;
$SHADOW_BG_GREY: rgba(0, 0, 0, 0.08);
$BB_COLOR: #e5e5e5;
$CHOOZEN: #55bb31;
$CHOOZEN_BORDER: #aad39c;
$HOVER_VOTE_ITEM: #d5ffc7;
$HINTS_COLOR: #014a6c;
$NUMBER_COLOR: #55bb31;
$DISABLED_BG: #ebebeb;
$DISABLED_COLOR: #cacaca;
$CLOSE_TO_END_TIME: #ff8c1b;
$MIDDLE_TIME: #e9d100;
$GREEN_LINE: #82e900;
$DEFAULT_LINE: #e5e5e5;
$FILTER_ACTIVE_BG: #b8ddff;
$ACTIVE_MENU_LINK: #006419;
$DROPZONE_BG: #c1d1bc;
$DROPZONE_BG_HOVER: #b3c9ac;
$DANGER_COLOR: #ff6969;
$SUPPORT_COLOR_TXT: #c0c0c0;
$STATUS_FINISHED: #00a3ff;
$POPUP_BG: #fbfbfb;
$DEFAULT_CHECK_SUPPORT_TEXT_COLOR: #9f9f9f;
$ACTIVE_BTN_BG: #b8ddff;
$SOURCE_TEXT_COLOR: #c0c0c0;
$ACTIVE_NEWS_TXT_COLOR: #006419;
$SCALE_BTN_BG: rgba(33, 33, 33, 0.72);
$H4_FONT_SIZE: 16px;
$H2_FONT_SIZE: 24px;
$H2_LINE_HEIGHT: 32px;
$MORE_BUTTON_BG: #fbfbfb;
$SCROLLBAR_BTN_COLOR: #555555;
$SCROLLBAR_DECREMENT_COLOR: #ebebeb;
$SCROLLBAR_INCREMENT_COLOR: #55bb31;
$SCROLLBAR_THUMB_COLOR: #c0c0c0;
$DEFAULT_CHECK_SUPPORT_TEXT_COLOR: #9f9f9f;
$NEWS_BG_COLOR: #fbfbfb;
$FILTER_HOVER: #b8ddff;
$SHOWFILE_BACKGROUND: #D7C100;
$BUTTON_HOVER: #47a725;
$BUTTON_ACTIVE: #3a9c18;
$VERTICAL_LINE: #c4c4c4;
$SHADOW_MENU: 0px 8px 16px 0px
  rgba(
    $color: #000000,
    $alpha: 0.16,
  );

$CHAT_SHADOW: -8px 0px 80px rgba(0, 0, 0, 0.16);
$CHAT_NOTIFICATION: $SECONDARY;
$CHAT_NOTIFICATION_BORDER: 2px solid $SECONDARY_BORDER;
$CHAT_NOTIFICATION_HOVER: $SECONDARY_HOVER;
$CHAT_NOTIFICATION_ACTIVE: $SECONDARY_ACTIVE;
$CHAT_NOTIFICATION_ACTIVE: $SECONDARY_ACTIVE_BORDER;
$CHAT_BG: #f5f5f0;
$CHAT_ITEM: #f8f8f8;
$CHAT_ITEM_BG: #d2e9cc;
$CHAT_ITEM_SHADOW: rgba(0, 0, 0, 0.04);
$CHAT_ITEM_INNER_SHADOW: #bfbfbf;
$CHAT_DESC: #cacaca;
$CHAT_MSG_USER: #fffbd8;
$CHAT_USER_SHADOW: 0px 2px 8px rgba(1, 74, 108, 0.08);
$CHAT_ADMIN_SHADOW: 0px 2px 8px rgba(85, 187, 49, 0.08);
$CHAT_ACTIVE: #55bb31;
$CHAT_DATE: #9f9f9f;
$CHAT_DATE_LINE: #d7ded5;
$CHAT_DISABLED_TEXT: #666666;

$POPUP_SHADOW: rgba(0, 0, 0, 0.32);

$BTN_PRIMARY: #55bb31;
$BTN_PRIMARY_BORDER: #82e900;
$BTN_PRIMARY_HOVER: #47a725;
$BTN_PRIMARY_CLICK: #3a9c18;
$BTN_PRIMARY_BORDER_CLICK: #47a725;
$BTN_DANGER: #ff6969;
$BTN_DANGER_HOVER: #ea4646;
$BTN_DANGER_CLICK: #da3737;
$BTN_SUB: #ffffff;
$BTN_SUB_TEXT: #55bb31;
$BTN_SUB_HOVER: #47a725;
$BTN_SUB_HOVER_TEXT: #ffffff;
$BTN_SUB_CLICK: #3a9c18;
$BTN_SECONDARY: $SECONDARY;
$BTN_SECONDARY_BORDER: $SECONDARY_BORDER;
$BTN_SECONDARY_HOVER: $SECONDARY_HOVER;
$BTN_SECONDARY_ACTIVE: $SECONDARY_ACTIVE;
$BTN_SECONDARY_ACTIVE_BORDER: $SECONDARY_ACTIVE_BORDER;

$ADDFILE_BG: $WHITE;
$ADDFILE_BG_HOVER: #47a725;
$ADDFILE_BG_ACTIVE: #3a9c18;

$IMG_BG: #006419;
$INPUT_BG: #f8f8f8;
$INPUT_PLACEHOLDER: #cacaca;
$INPUT_ERROR: #d0021b;

$TEXT_DISABLED: #c0c0c0;
