@import "../../../Common/vars.scss";

.filter {
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  gap: 8px;

  .submit-button {
    position: relative;
    bottom: 0;
    background: $MAIN_CONTAINER_BG;
    border: 1px solid $BB_COLOR;
    color: $MENU_TXT_COLOR;
    max-width: 100%;
    width: auto;
    padding: 1px 24px 1px 36px;
    height: 36px;
    line-height: 36px;
    // margin-right: 8px;

    span {
      line-height: 2;
    }

    &:after {
      position: absolute;
      content: "";
      width: 6px;
      height: 10px;
      border-bottom: 2px solid $DEFAULT_LINE;
      border-right: 2px solid $DEFAULT_LINE;
      left: 16px;
      transform: rotate(45deg);
      top: 10px;
    }

    &:hover {
      border: 1px solid $FILTER_HOVER;

      &:hover:after {
        border-bottom: 2px solid $WHITE;
        border-right: 2px solid $WHITE;
      }
    }

    &.true {
      background: $FILTER_ACTIVE_BG;
      border: 1px solid $FILTER_ACTIVE_BG;

      &:after {
        position: absolute;
        content: "";
        width: 6px;
        height: 10px;
        border-bottom: 2px solid $CHOOZEN;
        border-right: 2px solid $CHOOZEN;
        left: 16px;
        transform: rotate(45deg);
        top: 10px;
      }
    }
  }
  &.media-query {
    display: none;
  }
}
