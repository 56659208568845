@import "../../../../Common/vars.scss";

@mixin NEWS_PREVIEW {
  font-family: $PT_SANS;
  font-style: normal;
  font-weight: bold;
  font-size: $NEWS_PREVIEW_TEXT_SIZE;
  line-height: $DEFAULT_LINE_HEIGHT;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $TILE_HEADER_COLOR;
}

@mixin NEWS_DATE {
  font-size: $DEFAULT_TEXT_SIZE;
  line-height: $DEFAULT_LINE_HEIGHT;
  font-family: $FONT_FAMILY;
  color: $MENU_TXT_COLOR;
}

.newsListItem {
  position: relative;
  border-radius: 4px;

  .newsItemImage {
    height: 144px;
    width: 100%;
    border-radius: 4px;
    background-size: cover;
    background-position: center;
  }

  .newsDate {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;

    .hasMedia {
      *:last-child {
        margin-left: 8px;
      }
    }
    @include NEWS_DATE;
  }

  h4 {
    margin-top: 8px;
    @include NEWS_PREVIEW;
    word-wrap: break-word;
  }

  h4.newsItemText {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 31px;
  }

  .line:before {
    position: absolute;
    bottom: 0;
    width: 40%;
    border-top: 1px solid $BB_COLOR;
    content: "";
  }

  .lineActive:before {
    position: absolute;
    bottom: 0;
    width: 45%;
    border-top: 1px solid $ACTIVE_NEWS_TXT_COLOR;
    content: "";
  }

  .active {
    color: $ACTIVE_NEWS_TXT_COLOR;
  }
}

.newsListItem:active,
.newsListItem:focus,
.newsListItem:hover {
  cursor: pointer;
  h4 {
    color: $ACTIVE_NEWS_TXT_COLOR;
  }

  .line:before {
    border-color: $ACTIVE_NEWS_TXT_COLOR;
  }
}

.TNews__posts {
  h4.newsItemText {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 120px;
    margin-bottom: 31px;
  }
}

@media (max-width: 1024px) {
  .newsListItem {
    .newsItemImage {
      height: 120px;
    }
  }
}

@media (max-width: 639px) {
  .newsListItem {
    width: 100%;
    margin-right: 0;
  }

  h4.newsItemText {
    -webkit-line-clamp: 3;
  }
}
