@import "../..//scss/colors.scss";
@import "../../scss//fonts.scss";

.pointsHistoryTabs {
  width: 100%;
  position: relative;
  display: flex;
  gap: 32px;
  flex-wrap: wrap;

  &__item {
    padding: 0;
    background-color: transparent;
    border: none;
    color: $color-green-1;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    font-family: $PT_SANS-BOLD;
    letter-spacing: 4%;

    &-active {
      color: #006419;
    }
  }
}
