@import "../../vars.scss";

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  font-family: $AG_HELVETICA;
  margin: 24px 0;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  border: 1px solid $BB_COLOR;
}

.container:hover input ~ .checkmark {
  background-color: $HOVER_VOTE_ITEM;
}

.container input:checked ~ .checkmark {
  border: 1px solid $CHOOZEN_BORDER;
}

.container input ~ .checkmark:after {
  display: block;
}

.checkmark:after {
  content: "";
  position: absolute;
  //   display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark.undefined:after,
.container .checkmark.false:after {
  top: 8px;
  left: 18px;
  width: 12px;
  height: 24px;
  transform: rotate(45deg);
  border-bottom: 2px solid $BB_COLOR;
  border-right: 2px solid $BB_COLOR;
  opacity: 0;
}

.container .checkmark:after {
  top: 8px;
  left: 18px;
  width: 12px;
  height: 24px;
  transform: rotate(45deg);
  border-bottom: 2px solid $CHOOZEN;
  border-right: 2px solid $CHOOZEN;
}
