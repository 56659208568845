@import "../../scss/colors.scss";

.purchases-item {
  background-color: $color-white-1;
  display: flex;
  box-shadow: 0px 4px 8px 0px $color-black-3;
  height: 112px;
  border-radius: 8px;

  &__image {
    max-width: 192px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-right: solid 1px $color-grey-3;
    border-radius: 8px 0 0 8px;

    &-img {
      object-fit: cover;
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    padding: 24px;
    padding-right: 16px;
  }

  &__title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    letter-spacing: 0.04em;
    max-height: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__link {
    cursor: pointer;
    color: $color-green-1;
    font-size: 16px;
    margin-top: 8px;
    display: flex;
    gap: 12px;
    align-items: center;

    &:hover {
      opacity: 0.8;
    }
  }
}

@media (max-width: 1200px) {
  .purchases-item {
    height: 128px;

    &__image {
      max-width: 138px;
    }

    &__title {
      max-height: 48px;
      -webkit-line-clamp: 2;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.02em;
    }
  }
}

@media (max-width: 800px) {
  .purchases-item {
    flex-direction: column;
    height: auto;
    border-radius: 8px;

    &__image {
      max-width: 100%;
      height: 120px;
      border-radius: 8px 8px 0 0;
    }

    &__info {
      padding: 16px;
      gap: 8px;
    }
  }
}

@media screen and (max-width: 480px) {
  .purchases-item {
    &__image {
      height: 96px;
    }
  }
}
