@import "../../scss/colors.scss";
@import "../../scss//fonts.scss";

#PageHeader {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  font-family: $PT_SANS-BOLD;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-bottom: 32px;
  color: $color-black-2;
}

@media (max-width: 640px) {
  #PageHeader {
    font-size: 24px;
  
  }
}
