.points-history {
  &__title {
    margin-bottom: 30px;
  }

  &__list {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .infinity-scroll {
    margin-top: 40px;
  }
  .date-separator {
    font-family: "PT Sans";
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #a8a8a8;
    margin: 24px 0;

    &:first-child {
      margin-top: 0;
    }
  }
}
