@import '../../vars.scss';

.Dropzone {
	width: 104px;
	height: 104px;
	background: $DROPZONE_BG;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: .3s;
	cursor: pointer;

	&:hover {
		background: $DROPZONE_BG_HOVER;
	}
}