@import "../../scss/colors.scss";
@import "../../scss/fonts.scss";

.esk-panel {
  padding: 48px 24px;
  background: $color-grey-8;

  &__content {
    margin: 0 auto;
    max-width: 888px;
    width: 100%;
  }

  &__title {
    font-size: 24px;
    text-transform: uppercase;
    color: $color-green-6;
    font-weight: bold;
    font-family: $PT_SANS;
    margin-bottom: 24px;
  }

  &__text {
    color: $color-black-1;
    margin-bottom: 16px;
  }

  &__link {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 7px;
      right: -28px;
      display: flex;
      height: 10px;
      width: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 3.98318H14.0705L10.8991 0.730883L11.6118 0L16 4.49997L11.6118 9L10.8991 8.26908L14.0705 5.01682H0V3.98318Z' fill='%2355BB31'/%3E%3C/svg%3E%0A");
    }
  }
}

@media (max-width: 640px) {
  .esk-panel {
    padding: 32px 44px 48px 24px;

    &__title {
      font-size: 18px;
    }
  }
}
