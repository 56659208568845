.linkBack {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "PT Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #55bb31;
  cursor: pointer;
  width: fit-content;
  border-radius: 4px;
  user-select: none;

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
