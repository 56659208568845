@import "../../../../Common/vars.scss";

.notifications-item {
  padding: 40px 48px 32px;
  background: $WHITE;
  box-shadow: 0px 2px 4px $SHADOW_BG_GREY;
  border-radius: 4px;
  position: relative;

  &__status {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    span {
      margin-left: 12px;
      color: $SUPPORT_COLOR_TXT;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
    }
  }

  &__type {
    color: $TEXT_TITLE;
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
  }

  &__title {
    color: $TEXT_TITLE;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 28px;
    margin-top: 8px;
    white-space: pre-line;
    letter-spacing: 0.04em;
  }

  &__link {
    margin-top: 16px;
    display: inline-block;
    line-height: 24px;
    letter-spacing: 0.02em;

    svg {
      margin-left: 12px;
    }
  }

  &__question {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 16px;

    > * {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1024px) {
  .notifications-item {
    padding: 40px 56px 32px;
  }
}

@media screen and (max-width: 639px) {
  .notifications-item {
    padding: 24px 16px;
    background-color: $WHITE;
  }
}
