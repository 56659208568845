@import "../../../Common/vars.scss";

.main {
  min-height: 100vh;
  justify-content: space-between;
}

.profile-edit {
  max-width: 840px;
  width: 100%;
  padding: 48px;
  background: $WHITE;
  box-shadow: 0px 2px 4px $SHADOW_BG_GREY;
  border-radius: 4px;
  display: flex;
  background-color: #FBFBFB;

  .Dropzone {
    min-width: 120px;
    height: 120px;
    margin-right: 48px;
    border-radius: 4px;
    overflow: hidden;

    @media (max-width: 713px) {
      margin-right: 24px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      color: $DROPZONE_BG;
    }
  }

  &__form {
    .vote-checkbox {
      display: flex;
      margin-top: 10px;

      .answer {
        width: 100%;

        box-shadow: none;
        -webkit-box-shadow: none;

        &__button {
          max-width: 100px;
        }

        &__text {
          margin: 0;
        }

        &__body {
          padding: 0;

          &-label {
            display: flex;
            justify-content: flex-end;
            flex-direction: row-reverse;
            flex-wrap: nowrap;
            gap: 20px;
            align-items: center;

            * {
              color: $SUPPORT_COLOR_TXT;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    flex: 1;
    max-width: 100%;
  }

  &__add-photo {
    &-title {
      text-align: center;
      margin: 20px;
    }

    &-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      &:hover {
        .profile-edit__add-photo-change-photo {
          transform: translateY(0);
        }

        .profile-edit__add-photo-delete-photo {
          display: block;
        }
      }
    }

    &-change-photo {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 10px;
      text-align: center;
      display: block;
      color: #eeeeee;
      background-color: rgba($color: #000, $alpha: 0.6);
      transform: translateY(100%);
      transition: 0.2s;
    }

    &-delete-photo {
      position: absolute;
      right: 0;
      top: 0;
      background-color: rgba($color: #000, $alpha: 0.6);
      color: #eeeeee;
      padding: 8px;
      outline: none;
      border: none;
      font-size: 12px;
      display: none;
    }
  }

  &__edit-section {
    display: block;
    width: 100%;

    &:not(:first-child) {
      margin-top: 20px;
    }

    p,
    .input-wrapper {
      margin: 0;
    }

    p {
      text-transform: uppercase;
      color: $SUPPORT_COLOR_TXT;
      margin-bottom: 8px;
    }

    .map-wrapper {
      margin-top: 20px;

      .map-component {
        z-index: 0;
      }
    }
  }

  &__map-header {
    display: flex;
    justify-content: space-between;
  }

  &__map-load-status {
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 6px;
    display: block;
    color: $DEFAULT_CHECK_SUPPORT_TEXT_COLOR;
  }

  &__show-map-btn {
    background: none;
    border: none;
    outline: none;
    color: #55bb31;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: auto;
    display: block;
  }

  &__btn-group {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }

    .radiobuttons-wrapper {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;

    .container {
      display: flex;
      flex-direction: row;
      margin-right: 16px;
      .checkmark {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        left: 8px;
        right: 0;
        text-align: center;

        &:after {
          left: 0;
          right: 0;
          text-align: center;
        }
      }

      .text-label {
        color: $DEFAULT_CHECK_SUPPORT_TEXT_COLOR;
      }

      & .checkmark:after {
        width: 8px;
        height: 8px;
        transform: rotate(45deg);
        background: $ACTIVE_MENU_LINK;
        border-radius: 50%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-bottom: none;
        border-right: none;
      }

      & input:checked ~ .checkmark {
        border: 1px solid $CHOOZEN_BORDER;
        background: $WHITE;
      }

      .checkmark {
        position: absolute;
        border: 1px solid $DISABLED_COLOR;
        background: $BB_COLOR;
      }
    }
  }
}

@media (max-width: 1100px) {
  .profile-edit {
    flex-direction: column;

    &__dropdown {
      margin-bottom: 16px;
    }
  }
}

@media (max-width: 810px) {
  .profile-edit {
    &__btn-group {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .Button {
        width: 100%;
      }
    }
  }
}

@media (max-width: 713px) {
  .profile-edit {
    width: 100%;
    padding: 24px;
  }
}

@media screen and (max-width: 639px) {
  .profile-edit {
    display: block;
    padding: 24px 0;
    background-color: #f6f6f6;
    box-shadow: none;

    .Dropzone {
      margin-bottom: 40px;
    }
  }

  .Button {
    margin: 0 3px;
  }
}

@media (max-width: 521px) {
  .profile-edit {
    padding: 14px;

    input {
      width: calc(100% - 15px);
    }
    .InputWithIcon input {
      width: 100%;
    }
  }
}
