.notifications {
  width: 100%;

  &__filter {
    &-adaptive {
      display: none;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

@media (max-width: 639px) {
  .notifications {
    &__filter {
      display: none;

      &-adaptive {
        display: block;
        margin-bottom: 24px;
      }
    }

    &__list {
      gap: 8px;
    }
  }
}
