@import "../../scss/colors.scss";

.productCard {
  background-color: $color-white-1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px 0px $color-black-3;
  height: 280px;
  border-radius: 8px;
  cursor: pointer;

  &__info {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding: 24px 32px 32px;
  }

  &__img {
    border-radius: 8px 8px 0 0;
    max-width: 100%;
    max-height: 120px;
    object-fit: cover;
    cursor: pointer;
  }
  &__non-photo {
    height: 120px;
  }

  &__price {
    color: $color-green-1;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 24px;
  }

  &__title {
    margin: 0;
    max-width: 100%;
    height: 50px;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    -ms-line-clamp: 2;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    margin-bottom: 12px;
  }

  .ButtonComponent {
    max-width: 100%;
    padding: 11px 12px;
  }
}
