@import "../../../Common/vars.scss";

.copyrights-container {
  display: flex;
  flex: 1 0;
  width: 100%;
  max-width: 1704px;
  align-self: center;
  font-family: $AG_HELVETICA;
  margin-bottom: 64px;
}
