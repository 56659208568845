@import "../../../Common/vars.scss";
@import "../../../Common/mixins.scss";

.violations {
  display: flex;
  flex-direction: column;
  position: relative;

  .violations__fixed.violations__mobileAppEnabled {
    padding-top: 0 !important;
  }

  &__header {
    .switchers {
      display: none;
    }
  }

  &__main {
    padding-top: 88px;
    border-top: 1px solid $BB_COLOR;
  }

  &__dropdown {
    display: none;
  }

  .to {
    display: flex;
    align-self: flex-start;
    margin-top: -62px;
    margin-left: 262px;
  }

  .to.check {
    margin-left: 0;
  }

  .buttonShowLeft {
    margin: 0;
  }
  .buttonShowRight {
    position: absolute;
    right: 0;
    bottom: 13px;
  }

  .controls {
    display: flex;
    justify-content: space-between;
  }

  .violations-column {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .header_title {
    font-size: 32px;
    font-family: "PT Sans Bold";
    margin: 0 0 32px;
    color: $HEADER_COLOR;
    font-weight: 900;
  }
}

.violations-items.map {
  .map-component {
    position: relative;
    z-index: 1;
    height: 640px;
  }

  .map-wrapper {
    z-index: 10;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      z-index: 10;
      pointer-events: none;
      box-shadow: inset 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    }
  }
}

.violations-items.list,
.violations-column {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 24px;

  .tile-item {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    background: $WHITE;

    &:hover {
      .header {
        transition: color 0.1s linear;
        color: $BUTTON_HOVER;
      }
    }

    &:active {
      .header {
        transition: color 0.1s linear;
        color: $BUTTON_ACTIVE;
      }
    }

    &__image {
      border-radius: 4px 4px 0 0;
      overflow: hidden;
      width: 100%;
      height: 144px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 100%;
        width: 100%;
        background-size: cover;
        object-fit: cover;
      }
    }

    &__date {
      padding: 24px 32px 14px 32px;

      span {
        color: $MENU_TXT_COLOR;
        font-size: 14px;
      }

      .violations-number {
        padding-right: 24px;
        position: relative;

        @include dash;
      }

      .violations-date {
        margin-left: 8px;
        font-size: 14px;
      }
    }

    .header {
      padding: 0 32px;
      font-weight: bold;
      text-transform: uppercase;

      p {
        margin-bottom: 16px;
        height: 72px;
        font-size: 16px;
      }
    }

    &__district {
      margin-left: 32px;
      padding-left: 24px;
      position: relative;
      margin-bottom: 16px;
      font-size: 14px;

      &:after {
        position: absolute;
        content: "";
        width: 12px;
        height: 16px;
        background: url("../../../Common/images/pin.png") 50% 50% no-repeat;
        left: 0;
        top: -1px;
      }
    }

    &__support {
      padding: 32px;
      padding-top: 0;
      font-size: 14px;
      span:not(.count) {
        color: $SUPPORT_COLOR_TXT;
        padding-right: 24px;
        position: relative;

        @include dash;
      }
      .count {
        color: $LINK_COLOR;
        margin-left: 8px;
        font-size: 14px;
      }
    }
  }
}

.button__add-problem {
  width: 100%;
  button {
    margin-bottom: 40px;
    width: 400px;
    max-width: 100%;
  }
}

.alice-carousel {
  .alice-carousel__stage-item {
    width: 264px !important;
    height: 380px;
    margin: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;

    .tile-item {
      height: 100%;
      width: 100%;
      cursor: pointer;
      position: relative;
      box-sizing: border-box;

      & > .item__status {
        padding: 7px 27px 8px 36px;

        &.inwork {
          &:after {
            top: 10px;
          }
        }

        &.moderated,
        &.created {
          &:after {
            top: 11px;
          }
        }

        &.finished {
          &:after {
            top: 11px;
          }
        }
      }

      &:hover {
        .header {
          transition: color 0.1s linear;
          color: $BUTTON_HOVER;
        }
      }

      &:active {
        .header {
          transition: color 0.1s linear;
          color: $BUTTON_ACTIVE;
        }
      }

      &__date {
        padding: 24px 32px 14px 32px;
        span {
          color: $MENU_TXT_COLOR;
        }

        .violations-number {
          padding-right: 24px;
          position: relative;
          font-size: 14px;

          @include dash;
        }

        .violations-date {
          margin-left: 8px;
          font-size: 14px;
        }
      }

      .header {
        padding: 0 32px;
        font-weight: bold;

        p {
          text-transform: uppercase;
          font-weight: bold;
          margin-bottom: 8px;

          margin-bottom: 16px;
          height: 72px;
          font-size: 16px;
        }
      }

      &__image {
        border-radius: 4px 4px 0 0;
        overflow: hidden;
        height: 144px;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 4px;
        }
      }

      &__district {
        margin-left: 32px;
        padding-left: 24px;
        position: relative;
        margin-bottom: 16px;
        font-size: 14px;

        &:after {
          position: absolute;
          content: "";
          width: 12px;
          height: 16px;
          background: url("../../../Common/images/pin.png") 50% 50% no-repeat;
          left: 0;
          top: -1px;
        }
      }

      &__support {
        padding: 32px;
        padding-top: 0;
        font-size: 14px;
        span:not(.count) {
          color: $SUPPORT_COLOR_TXT;
          padding-right: 24px;
          position: relative;

          @include dash;
        }
        .count {
          color: $LINK_COLOR;
          margin-left: 8px;
        }
      }
    }
  }
  .alice-carousel__stage-item,
  .alice-carousel__stage-item.__active,
  .alice-carousel__stage-item.__cloned {
    background: $WHITE;
    box-shadow: $SHADOW;
    border-radius: 4px;
    cursor: pointer;
  }
  .alice-carousel__dots {
    display: none;
  }

  .alice-carousel__next-btn,
  .alice-carousel__prev-btn {
    width: 120px;
    margin-right: 8px;
    margin: 0;
    padding: 0;
  }
  .button {
    display: inline-block;
    margin-right: 8px;
    height: 48px;
    border: none;
    margin-top: 26px;

    &.prev,
    &.next {
      width: 120px;

      &:after {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        top: calc(50% - 5px);
        left: calc(50% - 5px);
        border-top: 3px solid $WHITE;
      }
    }

    &.next:after {
      border-left: 3px solid $WHITE;
      transform: rotate(135deg);
    }

    &.prev:after {
      border-right: 3px solid $WHITE;
      transform: rotate(-135deg);
    }
  }
}

@media (max-width: 1440px) and (min-width: 1201px) {
  .violations {
    .violations-items.list {
      grid-template-columns: repeat(4, 1fr);
    }

    .controls {
      margin-top: 24px;
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 1200px) and (min-width: 900px) {
  .violations {
    .violations-items.list {
      grid-template-columns: repeat(3, 1fr);
    }

    .controls {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 900px) and (min-width: 640px) {
  .violations {
    .violations-items.list {
      grid-template-columns: repeat(2, 1fr);
    }

    .controls {
      flex-direction: column;

      .filter {
        flex-direction: column;
      }
    }
  }
}

@media (min-width: 639px) {
  .violations-column {
    display: none !important;
  }
}

@media (max-width: 639px) {
  .violations {
    padding-top: 120px;

    &__fixed {
      padding-top: 65px;
    }

    &__mobileAppEnabled {
      padding-top: 80px;
    }

    &__header {
      z-index: 11;
      padding: 40px 24px 0;
      position: fixed;
      background: $MAIN_CONTAINER_BG;
      top: 80px;
      right: 48px;
      left: 0px;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
      border-radius: 0px 0px 8px 8px;
      transition: 0.3s;

      &-mobileAppEnabled {
        top: 160px;
      }

      .switchers {
        width: 100%;
        display: block;
        .filter {
          margin-bottom: 16px;
          width: 100%;
          flex-direction: row;
        }
        .submit-button {
          padding: 0;
          flex: 1;
          min-width: 20px;
          height: 30px;

          &:after {
            display: none;
          }

          svg {
            path {
              fill: $CHAT_DATE;
            }
          }

          &.true {
            svg {
              path {
                fill: $MENU_TXT_COLOR;
              }
            }
          }
        }
      }

      &-fixed {
        padding-top: 0;
        background-color: $WHITE;

        .switchers {
          .filter {
            margin-bottom: 24px;
          }
        }

        h2.violations-title {
          display: none;
        }
      }

      h2.violations-title {
        margin-bottom: 16px;
      }
    }

    .showing {
      display: none;
    }

    &__filter {
      display: none;
    }

    &__dropdown {
      display: block;
      margin-bottom: 32px;
    }

    &__main {
      padding-top: 64px;
      border-top: 1px solid #e5e5e5;
    }

    .filter {
      flex-direction: column;
    }

    .header_title {
      font-size: 24px;
      margin-bottom: 40px;
    }

    .violations-items.list {
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;
    }

    .controls {
      flex-direction: column;
      display: none;

      .filter {
        flex-direction: column;
      }
    }

    .trueNav {
      position: relative;
      bottom: auto;
      right: auto;
      margin: auto;
    }
    .to {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 0;
      margin-top: 24px;

      span {
        margin: 0;
      }
    }
    .buttonShowRight,
    .buttonShowLeft {
      position: relative;
    }
  }

  .alice-carousel {
    display: none;
  }

  .violations-column {
    display: flex;
    gap: 16px;
  }

  .violations__fixed.violations__mobileAppEnabled {
    padding-top: 25px;
  }
}
