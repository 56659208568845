@import "../../../Common/vars.scss";
@import "../../../Common/mixins.scss";

.MyStatements {
  font-family: $AG_HELVETICA;
  max-width: 840px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: $MAIN_CONTAINER_BG;

  & > button {
    width: 100%;
  }

  &__all {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;

    .tile-item {
      box-shadow: $SHADOW;
      border-radius: 4px;
      cursor: pointer;
      position: relative;
      background: $WHITE;
      box-sizing: border-box;

      &:hover {
        .header {
          transition: color 0.1s linear;
          color: $BUTTON_HOVER;
        }
      }

      &:active {
        .header {
          transition: color 0.1s linear;
          color: $BUTTON_ACTIVE;
        }
      }

      &__image {
        border-radius: 4px 4px 0 0;
        overflow: hidden;
        width: 100%;
        height: 144px;
        display: flex;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          background-size: cover;
          object-fit: cover;
        }
      }

      &__date {
        padding: 24px 32px 14px 32px;

        span {
          color: $MENU_TXT_COLOR;
        }

        .violations-number {
          padding-right: 24px;
          position: relative;
          font-size: 14px;

          @include dash;
        }

        .violations-date {
          margin-left: 8px;
          font-size: 14px;
        }
      }

      .header {
        padding: 0 32px;
        font-weight: bold;

        p {
          text-transform: uppercase;
          font-weight: bold;
          margin-bottom: 16px;
          height: 72px;
          font-size: 16px;
        }
      }

      &__district {
        margin-left: 32px;
        padding-left: 24px;
        position: relative;
        margin-bottom: 16px;
        font-size: 14px;

        &:after {
          position: absolute;
          content: "";
          width: 12px;
          height: 16px;
          background: url("../../../Common/images/pin.png") 50% 50% no-repeat;
          left: 0;
        }
      }

      &__support {
        padding: 32px;
        padding-top: 0;
        font-size: 14px;
        span:not(.count) {
          color: $SUPPORT_COLOR_TXT;
          padding-right: 24px;
          position: relative;

          @include dash;
        }
        .count {
          color: $LINK_COLOR;
          margin-left: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 1164px) {
  .MyStatements {
    &__all {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 770px) {
  // fix width of window and horizontal scroll
  .MyStatements {
    &__all {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@media screen and (max-width: 639px) {
  .MyStatements {
    padding: 0 0 24px;

    .Button {
      margin: 0;
    }

    .more.button {
      width: 100%;
    }
  }
}
