@import "../../vars.scss";

.ShowFile {
  max-width: 104px;
  max-height: 104px;
  border-radius: 8px;
  background: $BLACK_COLOR;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &__document {
    background: $SHOWFILE_BACKGROUND;

    .ShowFile__panel {
      width: 100%;
    }
  }

  &__file {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    opacity: 0.8;
  }

  &__delete {
    outline: none;
    border: none;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 8px;
    right: 8px;
    background: $BLACK_COLOR;
    background: rgba(33, 33, 33, 0.72);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      width: 12px;
      height: 12px;
    }
  }

  &__panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__type {
    height: 104px;
    width: 100%;

    &-icon {
      position: absolute;
      width: 19px;
      height: 19px;
      left: 16px;
      top: 16px;
      display: block;
      background-repeat: no-repeat;

      &-photo {
        background-image: url("data:image/svg+xml,%3Csvg width='19' height='16' viewBox='0 0 19 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.9643 2.66665H14.1014L13.5511 0.50465C13.4755 0.208047 13.2041 0 12.8929 0H6.10714C5.7959 0 5.52457 0.208047 5.44891 0.50465L4.89859 2.66665H2.03571C0.911419 2.66665 0 3.56208 0 4.66665V14C0 15.1046 0.911419 16 2.03571 16H16.9643C18.0886 16 19 15.1046 19 14V4.66665C19 3.56208 18.0886 2.66665 16.9643 2.66665ZM9.49998 12.6667C7.62617 12.6667 6.10714 11.1743 6.10714 9.33334C6.10714 7.4924 7.62617 6.00001 9.49998 6.00001C11.3738 6.00001 12.8928 7.4924 12.8928 9.33334C12.8906 11.1734 11.3729 12.6645 9.49998 12.6667Z' fill='%23FBFBFB'/%3E%3C/svg%3E%0A");
      }

      &-video {
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6317 6.38961L1.07611 0.0896267C0.852222 -0.0321731 0.579222 -0.0293731 0.358222 0.0952267C0.135778 0.221226 0 0.450826 0 0.700025V13.3C0 13.5492 0.135778 13.7788 0.358222 13.9048C0.470889 13.9678 0.596556 14 0.722222 14C0.843556 14 0.966333 13.9706 1.07611 13.9104L12.6317 7.61041C12.8584 7.48581 13 7.25341 13 7.00001C13 6.74661 12.8584 6.51421 12.6317 6.38961Z' fill='%23FBFBFB'/%3E%3C/svg%3E%0A");
      }

      &-document {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.99999 2.00001H8V2.99999H10V0H0V2.99999H2.00001V2.00001H4.00002V12H2.00001V14H7.99901V12H5.99999V2.00001Z' fill='%23FBFBFB'/%3E%3Cpath d='M8 5.00002V8.00001H10V7.00003H11V12H10V14H14V12H13V6.99999H14V7.99998H16V4.99999L8 5.00002Z' fill='%23FBFBFB'/%3E%3C/svg%3E");
      }

      &-audio {
        background-image: url("data:image/svg+xml,%3Csvg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.4345 0.0609734L6.29743 2.17833C5.43276 2.41754 4.73191 3.29214 4.73191 4.13061V11.3299C4.73191 11.3299 4.73191 11.4283 4.73191 11.4308C4.73191 11.4308 4.21478 11.0925 3.06295 11.2508C1.37152 11.4837 0 12.7427 0 14.0633C0 15.3839 1.37152 16.1931 3.06295 15.9602C4.75567 15.7284 5.99615 14.5081 5.99615 13.1869C5.99615 13.1869 5.99615 7.53932 5.99615 6.95376C5.99615 6.3682 6.72206 6.11716 6.72206 6.11716L13.0323 4.19914C13.0323 4.19914 13.7306 3.97176 13.7306 4.59844C13.7306 5.22511 13.7306 9.60124 13.7306 9.60124C13.7306 9.60124 13.7306 9.60248 13.7306 9.60497C13.7306 9.60497 13.0876 9.24616 11.9358 9.38196C10.2443 9.58068 8.87281 10.8135 8.87281 12.1335C8.87281 13.4547 10.2443 14.2907 11.9358 14.0914C13.6285 13.8926 15 12.6605 15 11.3392V1.14613C15 0.307656 14.2985 -0.178234 13.4345 0.0609734Z' fill='%23FBFBFB'/%3E%3C/svg%3E%0A");
      }
    }
  }
}
