@import "../Common/vars.scss";

.main-container {
  display: flex;
  max-width: 1920px;
  width: 100%;
  flex: 1;
  padding: 88px 0;
  flex-direction: column;
  margin: 0 auto;
  background: $MAIN_CONTAINER_BG;
  margin-top: 112px;

  .Wrapper {
    padding: 0 108px;
  }

  h4 {
    font-family: $PT_SANS;
    font-style: normal;
    font-weight: bold;
    font-size: $H4_FONT_SIZE;
    line-height: $DEFAULT_LINE_HEIGHT;
    letter-spacing: 0.04em;
    color: $MENU_TXT_COLOR;
    text-transform: uppercase;
  }

  h2 {
    font-family: $PT_SANS;
    font-style: normal;
    font-weight: bold;
    font-size: $H2_FONT_SIZE;
    line-height: $H2_LINE_HEIGHT;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $TILE_HEADER_COLOR;
  }

  &.space > *:not(:first-child) {
    margin-top: 80px;
  }
}

.main-container__homePage {
  display: none;
}

@media (max-width: 1900px) and (min-width: 1024px) {
  .main-container {
    padding: 80px 0;

    .Wrapper {
      padding: 0 124px;

      &__mainPage {
        padding: 0 44px;
      }
    }
  }
}

@media (max-width: 1023px) and (min-width: 640px) {
  .main-container {
    padding: 80px 0;
    margin-top: 88px;

    .Wrapper {
      padding: 0 24px;
    }
  }
}
@media (max-width: 1024px) {
  .main-container {
    margin-top: 88px;
  }
}
@media (max-width: 639px) {
  .main-container {
    padding: 40px 0;
    margin-top: 80px;
    margin-left: 0;
    margin-right: auto;
    margin-top: 80px;

    &__settings {
      padding-bottom: 0;
    }

    .Wrapper {
      padding: 0 24px;
    }
  }

  .main-container.mobileAppEnabled {
    padding-top: 160px;
  }
}
