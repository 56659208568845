@import "../../scss/colors.scss";

.product-category {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid $color-grey-3;
  padding: 0 32px 0 16px;
  height: 40px;
  border-radius: 4px;
  background-color: $color-blue-1;
}
