@import "../../scss/colors.scss";
@import "../../scss/fonts.scss";

.esk-info {
  &__content {
    margin: 0 auto;
    max-width: 888px;
    width: 100%;
  }

  &__title {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: $PT_SANS;
    margin-bottom: 48px;
  }

  &__text {
    color: $color-black-1;
    margin-bottom: 24px;
  }
}

@media (max-width: 640px) {
  .esk-info {
    &__title {
      font-size: 18px;
    }
  }
}
