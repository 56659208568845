@import "../../../Common/vars.scss";

.logo {
  display: flex;
  cursor: pointer;

  &,
  & span {
    font-family: $FONT_FAMILY;
    text-decoration: none;
    line-height: 16px;
    color: $BLACK_COLOR;
    font-size: 12px;
    outline: none;
  }

  svg {
    margin-right: 12px;
  }
  span {
    max-width: 90px;
  }
}
