@import "../../scss/colors.scss";

.multi-selection {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__list {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
  }

  &__title {
    font-size: 14px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: $color-grey-1;
    border: 1px solid $color-grey-3;
    padding: 10px 24px 10px 16px;
    border-radius: 4px;

    &:active {
      background-color: $color-blue-1;
    }

    &-active {
      border: 1px solid $color-blue-1;

      &:active {
        background-color: inherit;
      }

      background-color: $color-blue-1;
    }
  }
}
