@import "../../../Common/vars.scss";

.DropDown {
  font-family: $AG_HELVETICA;
  color: $MENU_TXT_COLOR;
  position: relative;
  height: 48px;
  display: flex;
  font-size: $DEFAULT_TEXT_SIZE;

  button {
    transition: 0.3s;
    background: none;
    outline: none;
    border: none;
    position: absolute;
    right: 0;
    height: 44px;
    width: 48px;
    padding: 0 12px;
    pointer-events: none;

    &.active {
      transform: rotate(180deg);
    }
  }

  input {

    &.success {
      // box-shadow: none;
    }
    
    &:focus {
      border-radius: 4px 4px 0 0;
    }
  }

  ul {
    overflow: hidden;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 11;
    border-radius: 0 0 4px 4px;
    transition: 0.1s;
    max-height: 0px;
    background: $WHITE;

    &.active {
      max-height: 312px;
      border: 2px solid $ACTIVE;
      border-top: none;
      overflow-y: scroll;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 2px;
        background: $WHITE;
      }
    }

    li {
      height: 51px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;

      &:hover {
        background: $BOX_SHADOW;
      }

      &::after {
        content: "";
        position: absolute;
        right: 16px;
        left: 16px;
        bottom: 0;
        height: 1px;
        background: #ebebeb;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &-error {
    opacity: 1;
    padding: 20px;
    max-height: 500px;
    color: $WHITE;
    transition: 0.3s;
    background: $INPUT_ERROR;
    font-weight: 700;
    font-size: 16px;
    position: absolute;
    left: 0;
    right: 0;
    top: 70px;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      left: 16px;
      top: -6px;
      width: 16px;
      height: 16px;
      background: $INPUT_ERROR;
      transform: rotate(45deg);
      transition: transform 0.3s;
    }
  }
}

@media (max-width: 639px) {
  .DropDown {
    ul {
      top: 38px;

      &.active {
        border-width: 1px;
      }
    }
  }
}
