@import "../../../Common/vars.scss";
@import "../../../Common/mixins.scss";

.MyStatements__popup {
  font-family: $AG_HELVETICA;
  max-width: 840px;
  width: 100%;
  padding: 48px;
  display: flex;
  flex-direction: column;
  box-shadow: $SHADOW;
  border-radius: 8px;
  background-color: $POPUP_BG;

  & > button {
    width: 100%;
    margin-bottom: 28px;
    display: none;
  }

  .component-date {
    margin-top: 0;
  }

  & * {
    div.files-block {
      padding-left: 0;
      padding-right: 0;
      box-shadow: none;
    }
  }
}

@media screen and (max-width: 1024px) {
  .MyStatements__popup {
    & > button {
      display: block;
    }
  }
}

@media screen and (max-width: 700px) {
  // fix width of window and horizontal scroll
}

@media screen and (max-width: 639px) {
  .MyStatements__popup {
    padding: 0 0 24px;
    max-width: 640px;
    box-shadow: none;
    background: $MAIN_CONTAINER_BG;

    .map-wrapper {
      width: calc(100% + 48px);
      transform: translateX(-24px);
    }
  }
}
