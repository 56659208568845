@import "../../../Common/vars.scss";

.quartelry {
  display: flex;

  .header_title {
    margin-top: 0;
  }

  .table {
    margin-top: 100px;
    &-item {
      display: flex;
      min-height: 260px;
      .rate {
        display: flex;
        margin-right: 60px;
        position: relative;

        .numbers {
          position: absolute;
          top: 42px;
          left: 3px;
          color: #55bb31;
          font-family: "PT Sans";
          font-size: 24px;
        }
      }
      .unit {
        position: relative;
        .img {
          position: absolute;
          display: flex;
          height: 160px;
          width: 160px;
          background-image: url("../../../Common/images/photo_2022-01-21_10-58-25.jpg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 0 0;
          border-radius: 8px;
        }
        .img2 {
          position: absolute;
          display: flex;
          height: 160px;
          width: 160px;
          background-image: url("../../../Common/images/photo_2022-01-21_10-58-29.jpg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 0 0;
          border-radius: 8px;
        }
        .img3 {
          position: absolute;
          display: flex;
          height: 160px;
          width: 160px;
          background-image: url("../../../Common/images/photo_2022-01-21_10-58-33.jpg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 65% 0;
          border-radius: 8px;
        }
        .img4 {
          position: absolute;
          display: flex;
          height: 160px;
          width: 160px;
          background-image: url("../../../Common/images/photo_2022-01-21_10-58-37.jpg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 0 0;
          border-radius: 8px;
        }
        .img5 {
          position: absolute;
          display: flex;
          height: 160px;
          width: 160px;
          background-image: url("../../../Common/images/photo_2022-01-21_10-58-40.jpg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 0 0;
          border-radius: 8px;
        }
        .img6 {
          position: absolute;
          display: flex;
          height: 160px;
          width: 160px;
          background-image: url("../../../Common/images/photo_2022-01-21_10-58-43.jpg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 0 0;
          border-radius: 8px;
        }
        .img7 {
          position: absolute;
          display: flex;
          height: 160px;
          width: 160px;
          background-image: url("../../../Common/images/photo_2022-01-21_10-58-46.jpg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 0 0;
          border-radius: 8px;
        }
        .img8 {
          position: absolute;
          display: flex;
          height: 160px;
          width: 160px;
          background-image: url("../../../Common/images/photo_2022-01-21_10-58-50.jpg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 0 0;
          border-radius: 8px;
        }
        .img9 {
          position: absolute;
          display: flex;
          height: 160px;
          width: 160px;
          background-image: url("../../../Common/images/photo_2022-01-21_10-58-53.jpg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 0 0;
          border-radius: 8px;
        }
        .img10 {
          position: absolute;
          display: flex;
          height: 160px;
          width: 160px;
          background-image: url("../../../Common/images/photo_2022-01-21_10-57-56.jpg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 0 0;
          border-radius: 8px;
        }
        .info {
          margin-left: 240px;
        }
        .name {
          font-size: 24px;
          display: flex;
          margin-bottom: 20px;
        }
        .district {
          font-size: 20px;
          display: flex;
          margin-bottom: 10px;
        }
        .territory {
          margin-bottom: 20px;
          display: flex;
        }
      }
    }
  }
}
