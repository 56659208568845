@import "../../vars.scss";

.checklist-wrapper {
  display: flex;
  flex-direction: column;
  font-family: $AG_HELVETICA;
  .checkbox {
    position: relative;
    margin-bottom: 24px;
    cursor: pointer;
    input[type="checkbox"] {
      display: none;
    }
    input:checked ~ .checkmark {
      background-color: $WHITE;
      border-radius: 50%;
      border: 1px solid $CHOOZEN_BORDER;
    }
    .checkmark {
      position: absolute;
      left: -32px;
      height: 48px;
      width: 48px;
      background-color: $WHITE;
      border-radius: 50%;
    }
    .checkmark:after {
      content: "";
      position: absolute;
      //   display: none;
    }

    .checkmark.undefined:after,
    .checkmark.false:after {
      top: 8px;
      left: 18px;
      width: 12px;
      height: 24px;
      transform: rotate(45deg);
      border-bottom: 2px solid $BB_COLOR;
      border-right: 2px solid $BB_COLOR;
    }

    input:checked ~ .checkmark:after {
      display: block;
      top: 8px;
      left: 18px;
      width: 12px;
      height: 24px;
      border: solid $CHOOZEN;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}
